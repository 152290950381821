import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { sendSimpleEvent } from '../ui/Events';

interface NavItemProps {
	title: string;
	url: string;
}

export default function NavItem({ title, url }:NavItemProps) {
	let location = useLocation();
	let paths = location.pathname.split('/');
	return (
		<Link className="inline phor f-uppercase f-600 t-blue mobile:phor-half mobile:f-small" key={title} to={`/${url}`} onClick={() => sendSimpleEvent({ event: 'click', title: `Menu Item ${title}` })}>
			{title}
			{paths.length > 1 && paths[1].includes(url) && <div className="red h-3px" />}
		</Link>
	);
}
