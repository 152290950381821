import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getPolls, slugify } from '../data/api';
import Button from '../components/ui/forms/Button';
import TotalVotes from '../components/polls/results/TotalVotes';

export default function Admin() {
	const [loading, setLoading] = useState(true);
	const [items, setItems] = useState([{
		attributes: {
			title: '',
			cover: {
				data: {
					attributes: {
						url: ''
					}
				}
			}
		},
		id: ''
	}]);

	const polls = async () => {
		const data = await getPolls({ limit: 500, start: 0, featured: false });
		console.log(data.data);
		setItems(data.data);
		setLoading(false);
	};
	useEffect(() => {
		if (loading) {
			polls();
		}
	}, [loading]);
	if (loading) {
		return (
			<div className="center p-2">
				Loading...
			</div>
		);
	}
	return (
		<div>

			<div className="max-w-940px mhor-auto mobile:p">
				<h4 className="mb center">
					Poll Results
				</h4>
				{items.map(({ attributes, id }) => (
					<div
						key={id}
						className="flex align-center p br grey100 mb mobile:block"
					>
						<div className="flex align-center grows">
							<Link className="shrinks-0" to={`/polls/${id}/${slugify(attributes.title)}`}>
								<img
									src={attributes.cover?.data?.attributes?.url}
									alt={attributes.title}
									className="w-200px mobile:w-100px mobile:mr ratio-1-1 obj-cover br mr-2"
								/>
							</Link>
							<h5 className="grows mr-2 mobile:mr-0 mobile:h6">
								{attributes.title}
							</h5>
						</div>

						<div className="pr center mobile:flex mobile:align-center mobile:mt mobile:pt mobile:b-top mobile:b-grey200 mobile:pr-0">
							<div>
								<h5 className="no-wrap t-blue mobile:grows mobile:left mobile:h6">
									<TotalVotes id={id} />
									&nbsp;
									Votes
								</h5>
								<em className="f-small mb op-60 lh-100 block">(Sums results for all the questions in the poll)</em>
							</div>
							<Button className="no-wrap mobile:f-small" height="mobile:h-2rem h-3rem" type="link" link={`/polls/${id}/results`}>
								View Results
							</Button>
						</div>

					</div>
				))}
			</div>
		</div>
	);
}
