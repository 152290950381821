import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getEntry } from '../data/api';
import PetitionsSlider from '../components/petitions/PetitionsSlider';
import Ad from '../components/home/Ad';

export default function PetitionRecommendation() {
	const { id } = useParams();
	const [loading, setLoading] = useState(true);
	const [item, setItem] = useState({
		attributes: {
			title: '',
			intro: '',
			createdAt: '',
			goal: 0,
			description: '',
			cover: {
				data: {
					attributes: {
						url: ''
					}
				}
			}
		},
		id: ''
	});

	const entry = async () => {
		const data = await getEntry({ id, endpoint: 'petitions' });
		setItem(data.data);
		setLoading(false);
	};
	useEffect(() => {
		if (loading) {
			entry();
		}
	}, [loading]);
	if (loading) {
		return (
			<div className="center p-2">
				Loading...
			</div>
		);
	}

	return (
		<div>
			<div className="max-w-940px mhor-auto">
				<Ad position="petitionsRecommendationHeader" />
			</div>
			<div className="max-w-800px mhor-auto pver mobile:phor">
				<div className="col-span2">
					<h3 className="h4 mver-2 center mobile:h6 t-blue">
						Thanks for your support: &nbsp;
					</h3>
					<h5 className="center  mobile:h6 op-60">
						{item.attributes.title}
					</h5>

					<p className="h6 f-400 mver-2 center mobile:f-1rem op-30">
						Please help us support more causes:
					</p>
				</div>
				<div>
					<PetitionsSlider start={0} limit={3} notEqual={Number(id)} />
				</div>
			</div>
			<div className="max-w-940px mhor-auto">
				<Ad position="petitionsRecommendationFooter" />
			</div>
		</div>

	);
}
