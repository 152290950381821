import React from 'react';
import {
	BrowserRouter,
	Routes,
	Route
} from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import Header from '../components/layout/Header';
import Home from '../pages/Home';
import Polls from '../pages/Polls';
import PollResults from '../pages/PollResults';
import Petitions from '../pages/Petitions';
import Petition from '../pages/Petition';
import PetitionRecommendation from '../pages/PetitionRecommendation';
import Articles from '../pages/Articles';
import Article from '../pages/Article';
import Footer from '../components/layout/Footer';
import Profile from '../pages/Profile';
import Privacy from '../pages/Privacy';
import Terms from '../pages/Terms';
import PollNew from '../pages/PollNew';
import Admin from '../pages/Admin';

import '../styles/index';
import Unsubscribe from '../pages/Unsubscribe';
import DoNotSell from '../pages/DoNotSell';
import Login from '../pages/Login';

export default function App() {
	return (
		<BrowserRouter>
			<RecoilRoot>
				<div className="grows flex direction-column">
					<Header />
					<div className="grows">
						<Routes>
							<Route path="/" element={<Home />} />
							<Route path="/polls" element={<Polls />} />
							<Route path="/polls/:id" element={<PollNew />} />
							<Route path="/polls/:id/:url" element={<PollNew />} />
							<Route path="/polls/:id/results" element={<PollResults />} />
							<Route path="/petitions" element={<Petitions />} />
							<Route path="/petitions/:id" element={<Petition />} />
							<Route path="/petitions/:id/recommendation" element={<PetitionRecommendation />} />
							<Route path="/petitions/:id/:url" element={<Petition />} />
							<Route path="/read" element={<Articles />} />
							<Route path="/read/:id" element={<Article />} />
							<Route path="/read/:id/:url" element={<Article />} />
							<Route path="/profile" element={<Profile />} />
							<Route path="/privacy" element={<Privacy />} />
							<Route path="/terms" element={<Terms />} />
							<Route path="/unsubscribe" element={<Unsubscribe />} />
							<Route path="/do-not-sell" element={<DoNotSell />} />
							<Route path="/admin" element={<Admin />} />
							<Route path="/admin" element={<Admin />} />
							<Route path="/login" element={<Login />} />
						</Routes>
					</div>
					<Footer />
				</div>
			</RecoilRoot>
		</BrowserRouter>
	);
}
