import React from 'react';
import { InlineFollowButtons } from 'sharethis-reactjs';

export default function ShareSite() {
	return (
		<InlineFollowButtons
			config={{
				action: 'Follow us:',
				action_enable: false,
				action_pos: 'bottom',
				alignment: 'left',
				color: 'social',
				enabled: true,
				networks: [
					'twitter',
					'facebook',
					'youtube',
					'linkedin',
					'reddit',
					'snapchat'
				],
				padding: 8,
				profiles: {
					twitter: 'Politicalcom1',
					facebook: '100086993048153',
					youtube: '/channel/UCeFravlXA3ycWhGpYjCBcvA?view_as=subscriber',
					linkedin: 'political-com',
					reddit: 'Political_com',
					snapchat: 'political.com'
				},
				radius: 9,
				size: 32,
				spacing: 8
			}}
		/>
	);
}
