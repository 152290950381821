import React from 'react';

interface LogoProps{
  className?: string;
}

export default function Logo({ className }:LogoProps) {
	return (
		<svg className={className} width="178" height="53" viewBox="0 0 178 53" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M10.9316 7.11328C8.68303 7.11328 6.71119 7.86427 5.18906 9.16143L4.3934 7.69359H0V32.7834H6.36525V25.5466C7.67981 26.3317 9.23653 26.7755 10.9316 26.7755C16.6396 26.7755 20.7216 22.6792 20.7216 16.9102C20.7216 11.1754 16.6396 7.11328 10.9316 7.11328ZM10.0668 21.1431C7.64522 21.1431 5.88094 19.3339 5.88094 16.9102C5.88094 14.5207 7.64522 12.7115 10.0668 12.7115C12.4537 12.7115 14.2526 14.5207 14.2526 16.9102C14.2526 19.3339 12.4537 21.1431 10.0668 21.1431Z" fill="#2A1F71" />
			<path d="M31.2198 26.9071C37.3378 26.9071 41.7526 22.7418 41.7526 16.993C41.7526 11.2442 37.3378 7.11328 31.2198 7.11328C25.1017 7.11328 20.7217 11.2442 20.7217 16.993C20.7217 22.7418 25.1017 26.9071 31.2198 26.9071ZM31.2198 21.1583C28.8907 21.1583 27.1874 19.4715 27.1874 17.0274C27.1874 14.5489 28.8907 12.8621 31.2198 12.8621C33.5836 12.8621 35.2869 14.5833 35.2869 17.0274C35.2869 19.4715 33.5836 21.1583 31.2198 21.1583Z" fill="#2A1F71" />
			<path d="M42.3711 26.2886H48.8659V1.23706H42.3711V26.2886Z" fill="#2A1F71" />
			<path d="M124.639 26.2886H130.825V1.23706H124.639V26.2886Z" fill="#2A1F71" />
			<path d="M52.3013 5.42955H58.4204V0H52.3013V5.42955ZM52.2681 26.2887H58.4536V7.73196H52.2681V26.2887Z" fill="#2A1F71" />
			<path d="M73.9507 5.42955H80.0698V0H73.9507V5.42955ZM73.9175 26.2887H80.103V7.73196H73.9175V26.2887Z" fill="#2A1F71" />
			<path d="M70.2697 20.7535C68.9455 20.7535 68.1306 19.9335 68.1306 18.6351V12.7584H72.5786V7.83835H67.9947V2.47412H66.6705L58.4536 11.2551V12.7584H61.8151V19.6943C61.8151 23.7944 64.2937 26.2886 68.3343 26.2886H72.6804V20.7535H70.2697Z" fill="#2A1F71" />
			<path d="M92.6307 26.9071C97.9164 26.9071 101.95 23.4932 102.68 18.4585H96.3168C95.7952 20.1137 94.3694 21.1828 92.6307 21.1828C90.3704 21.1828 88.736 19.4241 88.736 16.9757C88.736 14.5618 90.3704 12.8376 92.6307 12.8376C94.3347 12.8376 95.7604 13.8377 96.282 15.355H102.611C101.881 10.3893 97.9512 7.11328 92.6655 7.11328C86.58 7.11328 82.2681 11.2169 82.2681 16.9757C82.2681 22.7345 86.6148 26.9071 92.6307 26.9071Z" fill="#2A1F71" />
			<path d="M132.99 25.9497H136.328V22.7117H132.99V25.9497Z" fill="#D11111" />
			<path d="M143.283 26.2886C146.086 26.2886 148.225 24.4249 148.612 21.6763H145.238C144.961 22.58 144.205 23.1636 143.283 23.1636C142.084 23.1636 141.218 22.2034 141.218 20.8668C141.218 19.549 142.084 18.6077 143.283 18.6077C144.187 18.6077 144.943 19.1537 145.219 19.982H148.576C148.188 17.2711 146.105 15.4827 143.301 15.4827C140.074 15.4827 137.788 17.7229 137.788 20.8668C137.788 24.0107 140.093 26.2886 143.283 26.2886Z" fill="#D11111" />
			<path d="M155.08 26.2886C158.326 26.2886 160.668 24.0107 160.668 20.8668C160.668 17.7229 158.326 15.4639 155.08 15.4639C151.835 15.4639 149.511 17.7229 149.511 20.8668C149.511 24.0107 151.835 26.2886 155.08 26.2886ZM155.08 23.1447C153.845 23.1447 152.941 22.2223 152.941 20.8857C152.941 19.5302 153.845 18.6077 155.08 18.6077C156.334 18.6077 157.238 19.549 157.238 20.8857C157.238 22.2223 156.334 23.1447 155.08 23.1447Z" fill="#D11111" />
			<path d="M173.985 15.5015C172.75 15.5015 171.643 16.0663 170.924 16.9887C170.297 16.0475 169.283 15.5015 168.066 15.5015C166.922 15.5015 165.871 16.0286 165.152 16.8946L164.673 15.7839H162.257V25.9497H165.687V20.3774C165.687 19.116 166.203 18.4383 167.125 18.4383C167.844 18.4383 168.232 18.9843 168.232 20.0197V25.9497H171.569V20.3774C171.569 19.0972 172.049 18.4383 172.989 18.4383C173.727 18.4383 174.096 18.9466 174.096 20.0197V25.9497H177.526V19.5867C177.526 17.1958 176.124 15.5015 173.985 15.5015Z" fill="#D11111" />
			<path d="M118.34 7.66503L117.69 9.11335C116.186 7.83745 114.236 7.11328 112.048 7.11328C106.406 7.11328 102.371 11.2169 102.371 16.9757C102.371 22.769 106.406 26.9071 112.048 26.9071C114.202 26.9071 116.117 26.2174 117.622 24.976L118.169 26.2864H123.093V7.66503H118.34ZM112.903 21.2517C110.509 21.2517 108.765 19.4241 108.765 16.9757C108.765 14.5618 110.509 12.7687 112.903 12.7687C115.296 12.7687 117.04 14.5618 117.04 16.9757C117.04 19.4241 115.296 21.2517 112.903 21.2517Z" fill="#2A1F71" />
			<path d="M85.4729 32.0325H90.6716C92.3137 32.0325 93.5556 32.1604 94.3973 32.4162C95.2473 32.672 95.9322 33.1919 96.452 33.9758C96.9719 34.7515 97.2318 36.0058 97.2318 37.7386C97.2318 38.9104 97.0462 39.7274 96.6748 40.1895C96.3117 40.6516 95.5897 41.0064 94.5087 41.2539C95.7135 41.5263 96.5304 41.9801 96.9595 42.6155C97.3886 43.2427 97.6032 44.2081 97.6032 45.5119V47.3686C97.6032 48.7219 97.4464 49.7245 97.1328 50.3764C96.8275 51.0283 96.3365 51.4739 95.6598 51.7132C94.9832 51.9525 93.5969 52.0722 91.5009 52.0722H85.4729V32.0325ZM90.684 35.4611V39.9171C90.9068 39.9089 91.0801 39.9048 91.2038 39.9048C91.7154 39.9048 92.0414 39.781 92.1817 39.5334C92.322 39.2776 92.3921 38.5556 92.3921 37.3673C92.3921 36.7402 92.3343 36.3028 92.2188 36.0553C92.1033 35.7995 91.9506 35.6385 91.7608 35.5725C91.5793 35.5065 91.2203 35.4694 90.684 35.4611ZM90.684 43.0364V48.6435C91.4184 48.6188 91.8846 48.5032 92.0827 48.2969C92.289 48.0906 92.3921 47.5831 92.3921 46.7745V44.9054C92.3921 44.0472 92.3013 43.5273 92.1198 43.3458C91.9382 43.1643 91.4596 43.0611 90.684 43.0364Z" fill="#2A2356" />
			<path d="M99.4846 32.0325H108.174V36.0429H104.696V39.8429H107.951V43.6552H104.696V48.0618H108.52V52.0722H99.4846V32.0325Z" fill="#2A2356" />
			<path d="M126.493 32.0325V52.0722H121.282V43.6552H119.722V52.0722H114.511V32.0325H119.722V39.1992H121.282V32.0325H126.493Z" fill="#2A2356" />
			<path d="M128.597 32.0325H137.286V36.0429H133.808V39.8429H137.064V43.6552H133.808V48.0618H137.633V52.0722H128.597V32.0325Z" fill="#2A2356" />
			<path d="M148.142 32.0325L151.125 52.0722H145.79L145.53 48.4702H143.661L143.352 52.0722H137.955L140.604 32.0325H148.142ZM145.382 44.9178C145.118 42.6485 144.853 39.847 144.589 36.5132C144.061 40.3421 143.731 43.1436 143.599 44.9178H145.382Z" fill="#2A2356" />
			<path d="M152.016 32.0325H155.705C158.164 32.0325 159.826 32.1274 160.693 32.3172C161.568 32.507 162.277 32.9938 162.822 33.7777C163.375 34.5534 163.651 35.7953 163.651 37.5035C163.651 39.0631 163.457 40.1111 163.069 40.6474C162.682 41.1838 161.918 41.5056 160.78 41.6129C161.811 41.8687 162.504 42.2112 162.859 42.6403C163.214 43.0694 163.433 43.4655 163.515 43.8285C163.606 44.1834 163.651 45.1695 163.651 46.7868V52.0722H158.812V45.4129C158.812 44.3402 158.725 43.6759 158.552 43.4201C158.387 43.1643 157.945 43.0364 157.227 43.0364V52.0722H152.016V32.0325ZM157.227 35.4611V39.9171C157.813 39.9171 158.221 39.8388 158.453 39.682C158.692 39.5169 158.812 38.9929 158.812 38.11V37.0084C158.812 36.373 158.696 35.9562 158.465 35.7582C158.242 35.5602 157.83 35.4611 157.227 35.4611Z" fill="#2A2356" />
			<path d="M165.681 32.0325H169.58C172.097 32.0325 173.797 32.148 174.68 32.379C175.571 32.6101 176.248 32.9897 176.71 33.5178C177.172 34.0459 177.461 34.6359 177.576 35.2878C177.692 35.9315 177.75 37.2023 177.75 39.1002V46.1184C177.75 47.9173 177.663 49.1221 177.49 49.7328C177.325 50.3351 177.032 50.8096 176.611 51.1562C176.19 51.4945 175.67 51.7338 175.051 51.8741C174.432 52.0062 173.5 52.0722 172.254 52.0722H165.681V32.0325ZM170.892 35.4611V48.6435C171.643 48.6435 172.105 48.495 172.279 48.1979C172.452 47.8926 172.539 47.0715 172.539 45.7347V37.9491C172.539 37.0414 172.51 36.4596 172.452 36.2038C172.394 35.948 172.262 35.7623 172.056 35.6468C171.849 35.523 171.462 35.4611 170.892 35.4611Z" fill="#2A2356" />
		</svg>

	);
}
