import React, { useEffect, useState } from 'react';
import IconX from '../ui/icons/IconX';
import ProfileForm from '../profile/ProfileForm';
import SignInForm from '../profile/SignInForm';
import LegalNote from './LegalNote';
import { sendSimpleEvent } from '../ui/Events';

interface JoinLoginProps{
	title: string;
	description: string;
	next: () => void;
	close: () => void;
}

declare let gtag: Function;

export default function JoinLogin({ title, description, next, close }:JoinLoginProps) {
	const [signup, setSignup] = useState(true);
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		if (loading) {
			sendSimpleEvent({
				event: 'pageView',
				title: 'Sign Up'
			});
			if (typeof window !== 'undefined') {
				window.gtag('event', 'page_view', {
					page_title: 'Sign Up'
				});
			}
		}
	});
	return (
		<div className="fixed edges z-1000 flex align-start center justify-center overflow-x-scroll">
			<div className="fixed edges w-100 h-100 black80" onClick={close} />
			<div className="white br  max-w-700px w-100 pver-2 phor-4 center relative mobile:mhor mobile:phor-2">
				<div className="flex justify-end" onClick={close}>
					<button type="button" className="b-0 transparent cursor-pointer">
						<IconX />
					</button>
				</div>
				<h4 className="t-blue mb f-uppercase">
					{signup ? title : 'Sign In'}
				</h4>
				<div>
					{description}
				</div>

				{signup
					? (
						<div>
							<ProfileForm action="Join Now" next={next} />
							<div className="mt-half flex justify-center">
								Already have an account?
								<div className="ml-quarter cursor-pointer t-red transparent b-0 f-1rem" onClick={() => setSignup(!signup)}>Sign In</div>
							</div>
						</div>
					) : (
						<div>
							<SignInForm action="Sign In" next={next} />
							<div className="mt-half flex justify-center">
								Don&lsquo;t have an account?
								<div className="ml-quarter cursor-pointer t-red transparent b-0 f-1rem" onClick={() => setSignup(!signup)}>Sign Up</div>
							</div>
						</div>
					)}
				<LegalNote />
			</div>
		</div>
	);
}
