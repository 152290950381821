import React from 'react';
import SectionTitle from '../components/layout/SectionTitle';
import PollFeed from '../components/polls/PollFeed';
import Hero from '../components/home/Hero';
import PetitionsFeed from '../components/petitions/PetitionsFeed';
import ArticlesFeed from '../components/articles/ArticlesFeed';
import Ad from '../components/home/Ad';

export default function Home() {
	return (
		<div>
			<Hero />
			<div className="max-w-940px mhor-auto">
				<Ad position="homeHero" />
			</div>

			<div className="max-w-1056px mhor-auto pb b-bottom mb mobile:mb-0 mobile:w-100 phor mobile:p" style={{ borderColor: '#e7e7e7' }}>
				<SectionTitle title="Open Polls" link="/polls" />
				<PollFeed limit={2} start={1} />
			</div>

			<div className="max-w-940px mhor-auto">
				<Ad position="homePolls" />
			</div>

			<div className="max-w-1056px mhor-auto pb-2 b-bottom mb mobile:w-100 phor mobile:p  mobile:mb-0" style={{ borderColor: '#e7e7e7' }}>
				<SectionTitle title="Petitions" link="/petitions" />
				<PetitionsFeed limit={1} />
			</div>

			<div className="max-w-940px mhor-auto">
				<Ad position="homePetitions" />
			</div>

			<div className="max-w-1056px mhor-auto pb-2 b-bottom phor mobile:p" style={{ borderColor: '#e7e7e7' }}>
				<SectionTitle title="Read" link="/read" />
				<ArticlesFeed />
			</div>

			<div className="max-w-940px mhor-auto">
				<Ad position="homeArticles" />
			</div>
		</div>
	);
}
