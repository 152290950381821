import React from 'react';
import SectionTitle from '../components/layout/SectionTitle';
import PollFeed from '../components/polls/PollFeed';
import Ad from '../components/home/Ad';

export default function Polls() {
	return (
		<div>
			<div className="max-w-1056px mhor-auto pb b-bottom mb phor" style={{ borderColor: '#e7e7e7' }}>
				<div className="max-w-940px mhor-auto">
					<Ad position="pollsHeader" />
				</div>
				<div className="mobile:mt">
					<div className="mobile:hidden">
						<SectionTitle title="Open Polls" />
					</div>
				</div>
				<PollFeed limit={100} />
			</div>
			<div className="max-w-940px mhor-auto">
				<Ad position="pollsFeed" />
			</div>
		</div>
	);
}
