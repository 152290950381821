import React from 'react';

interface IconProps{
	className?: string;
	color?: string;
}

export default function IconChevronRight({ className, color }:IconProps) {
	return (
		<svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
			<path d="M10.5254 8.94922C10.5254 8.82552 10.4993 8.71159 10.4473 8.60742C10.4017 8.49674 10.3333 8.39583 10.2422 8.30469L2.50781 0.726562C2.33203 0.557292 2.11719 0.472656 1.86328 0.472656C1.70052 0.472656 1.55078 0.511719 1.41406 0.589844C1.27734 0.667969 1.16992 0.775391 1.0918 0.912109C1.01367 1.04232 0.974609 1.19206 0.974609 1.36133C0.974609 1.60221 1.05924 1.8138 1.22852 1.99609L8.33789 8.94922L1.22852 15.9023C1.05924 16.0846 0.974609 16.2962 0.974609 16.5371C0.974609 16.7064 1.01367 16.8561 1.0918 16.9863C1.16992 17.123 1.27734 17.2305 1.41406 17.3086C1.55078 17.3867 1.70052 17.4258 1.86328 17.4258C2.11719 17.4258 2.33203 17.3379 2.50781 17.1621L10.2422 9.59375C10.3333 9.5026 10.4017 9.40495 10.4473 9.30078C10.4993 9.1901 10.5254 9.07292 10.5254 8.94922Z" className={color || 'black'} />
		</svg>
	);
}
