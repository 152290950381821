import React, { useState, useEffect } from 'react';
import { OptionObj, PollVotesObj } from '../../../data/interfaces';

interface SubBarObj {
	label: string;
	value: number;
}

interface StackProps {
	options: OptionObj[];
	votes: PollVotesObj[];
	attributeValue: string;
	attribute: string;
}

export default function HorizontalStack({ options, votes, attribute, attributeValue }:StackProps) {
	let sub:SubBarObj[] = [];
	options.forEach((e) => {
		const filter = votes.filter((f) => f.attributes.answer === e.label);
		sub.push({ label: e.label, value: filter.length });
	});
	const [subBars, setSubBars] = useState<SubBarObj[]>(sub);
	const [colors, setColors] = useState(['graph1', 'graph2', 'graph3', 'graph4', 'graph5', 'graph6', 'graph1', 'graph2']);
	if (votes.length < 1) {
		return (<div />);
	}
	return (
		<div className="mb">
			<div className="flex align-center">
				<h6 className="grows mobile:f-1rem">
					{attributeValue}
				</h6>
				<div className="pver flex align-center">
					{subBars.map(({ label, value }, index) => (
						<div key={`${label}${value}${attributeValue}`}>
							{value > 0 && (
								<div className="flex ml mobile:f-small mobile:ml-half">
									{label}
									<div className={`w-1rem h-1rem ml-half mobile:ml-quarter ${colors[index]}`} />
								</div>
							)}
						</div>
					))}
				</div>
			</div>

			<div className="h-2rem blue flex br-quarter overflow-hidden">
				{subBars.map(({ label, value }, index) => {
					const width = (100 / votes.length) * value;
					return (
						<div key={`sub${label}`} className={`flex align-center justify-center t-white ${colors[index]}`} style={{ width: `${width}%` }}>
							{width > 0 && <span className="f-small">{`${width.toFixed(2)}%`}</span>}
						</div>
					);
				})}
			</div>
		</div>
	);
}
