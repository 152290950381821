import React from 'react';

interface BarVerticalProps {
	label: string;
	total: number;
	value: number;
}

export default function BarVertical({ label, total, value }:BarVerticalProps) {
	return (
		<div className="flex justify-start direction-column align-center center mr-2 mobile:mhor">
			<div className="w-5rem h-200px white10 mb relative" title={`${value.toString()} vote${value === 1 ? '' : 's'}`}>
				<div className="bottom-0 left-0 right-0 red absolute t-5" style={{ height: `${(value / total) * 100}%` }} />
			</div>
			<strong className="f-uppercase">{label}</strong>
			&nbsp;
			{`(${((value / total) * 100).toFixed(2)}%)`}
		</div>
	);
}
