import React, { useEffect, useState } from 'react';
import { getPolls } from '../../data/api';
import PollListBox from './PollListBox';

interface PollFeedProps {
	limit?: number;
	start?: number;
}

export default function PollFeed({ limit, start }:PollFeedProps) {
	const [loading, setLoading] = useState(true);
	const [items, setItems] = useState([{
		attributes: {
			title: '',
			cover: {
				data: {
					attributes: {
						url: ''
					}
				}
			}
		},
		id: ''
	}]);

	const polls = async () => {
		const data = await getPolls({ limit: limit || 2, start: start || 0, featured: false });
		setItems(data.data);
		setLoading(false);
	};
	useEffect(() => {
		if (loading) {
			polls();
		}
	}, [loading]);
	if (loading) {
		return (
			<div className="center p-2">
				Loading...
			</div>
		);
	}
	return (
		<div className="grid2 col-gap-2 mb-2 row-gap-2 mobile:mb-0 mobile:block">
			{items.map(({ attributes, id }) => (
				<PollListBox
					key={id}
					cover={attributes.cover?.data?.attributes?.url}
					title={attributes.title}
					id={id}
				/>
			))}
		</div>
	);
}
