import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../ui/forms/Button';
import Input from '../ui/forms/Input';
import { emailAtom, joinAtom, loadingPetitionBar, userAtom } from '../../data/atoms';
import Error from '../ui/forms/Error';
import { getSoftUser, savePetitionSignature, saveSoftUser, updateSoftUser } from '../../data/api';
import { sendPetitionEvent } from '../ui/Events';
import JoinLogin from '../home/JoinLogin';

interface PetitionProps {
	title: string;
}

const FormBox = styled.div`
  box-shadow: 0 0 3px rgba(0,0,0,0.3);
  border-radius: 8px;
  &:hover{
  }
`;

export default function PetitionSign({ title }:PetitionProps) {
	let navigate = useNavigate();
	const { id } = useParams();
	const [join, setJoin] = useState(false);
	const [error, setError] = useState('');
	const [emailLocal, setEmailLocal] = useRecoilState(emailAtom);
	const [email, setEmail] = useState(emailLocal);
	const [userLocal, setUserLocal] = useRecoilState(userAtom);
	const [loadingBar, setLoadingBar] = useRecoilState(loadingPetitionBar);

	const [user, setUser] = useState({
		firstName: userLocal?.attributes?.firstName || '',
		lastName: userLocal?.attributes?.lastName || ''
	});

	const validateEmail = (em:string) => {
		const eml = String(em).toLowerCase();
		return eml.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
	};
	const signPetition = async () => {
		console.log('Signing Petition');
		setError('');
		if (!emailLocal || !window.localStorage.getItem('email')) {
			console.log('No Email Local');
			setUserLocal({
				id: '',
				attributes: {
					firstName: user.firstName,
					lastName: user.lastName,
					email
				}
			});
			setEmailLocal(email);
			setJoin(true);
			console.log('return should stop');
			return;
		}
		console.log('Passed Email Local');
		if (!validateEmail(email)) {
			setError('Please enter a valid email');
			return;
		}
		if (!email) {
			setError('Please enter an email to continue');
			return;
		}
		if (!user.firstName || !user.lastName) {
			setError('Please enter first name and last name to continue');
			return;
		}
		if (window.localStorage.getItem('email')) {
			if (email === emailLocal) {
				console.log('Looking for ID', userLocal);
				await updateSoftUser(userLocal?.id, user);
				await savePetitionSignature({ user: userLocal?.id, petition: Number(id) });
				sendPetitionEvent({
					event: 'sign petition',
					title,
					id
				});
			} else {
				let u = await getSoftUser({ email });
				if (u.data.length > 0) {
					await savePetitionSignature({ user: u?.data?.id, petition: id });
				} else {
					const us = await saveSoftUser({ ...user, email });
					await savePetitionSignature({ user: us?.data?.id, petition: id });
				}
			}
			setLoadingBar(true);
			navigate(`/petitions/${id}/recommendation`);
		} else {
			console.log('No Session');
		}
	};
	const changeInput = (e:any) => {
		setUser({ ...user, [e.target.name]: e.target.value });
	};
	useEffect(() => {
		setEmail(emailLocal);
	}, [emailLocal]);
	return (
		<FormBox className="mb pver-2 phor">
			<h4 className="f-700 h5 t-blue f-uppercase">Sign Petition</h4>
			<div>
				<Input name="firstName" id="firstName" value={user?.firstName} onChange={changeInput} placeholder="First Name" label="First Name" />
			</div>
			<div>
				<Input name="lastName" id="lastName" value={user?.lastName} onChange={changeInput} placeholder="Last Name" label="Last Name" />
			</div>
			<div>
				<Input name="email" id="emailP" placeholder="Email" label="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
			</div>
			{error && <Error error={error} />}
			<div className="mt center">
				<Button type="button" color="red" className="w-100 mt" onClick={signPetition}>
					Sign Now
				</Button>
			</div>
			{join && (
				<JoinLogin
					title="Sign Up to Support Petition!"
					description="To see sign petition, please submit your email"
					close={() => setJoin(false)}
					next={() => signPetition()}
				/>
			)}
		</FormBox>
	);
}
