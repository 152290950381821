import React, { useEffect, useState } from 'react';
import PetitionSign from './PetitionSign';
import { getPetitionSignature } from '../../data/api';
import Button from '../ui/forms/Button';

interface SignatureProps {
	userId?: string;
	petitionId?: string;
	title: string;
}

export default function ({ userId, petitionId, title }:SignatureProps) {
	const [loadingSign, setLoadingSign] = useState(true);
	const [signed, setSigned] = useState(false);
	const [signDate, setSignDate] = useState('');

	const signature = async () => {
		if (userId) {
			const r = await getPetitionSignature(petitionId, userId);
			console.log('SIGNATURES', r.data);
			if (r.data.length > 1) {
				console.log('YES L', r.data[0]?.attributes?.createdAt);
				setSigned(true);
				setSignDate(r.data[0]?.attributes?.createdAt);
			} else {
				setSigned(false);
			}
		} else {
			setSigned(false);
		}
		setLoadingSign(false);
	};
	useEffect(() => {
		if (loadingSign) {
			signature();
		}
	}, [loadingSign]);

	return (
		<div>
			{loadingSign ? <div>Loading ...</div>
				: signed ? (
					<div className="center p b b-grey200 br mver">
						<p>
							You signed this petition
						</p>
						<h3 className="h5 t-blue mver">
							Thank you for your support!
						</h3>
						<Button type="link" link="/petitions">
							Support other causes
						</Button>
					</div>
				) : (
					<PetitionSign title={title} />
				)}
		</div>
	);
}
