import React, { useEffect, useState } from 'react';
import Button from '../ui/forms/Button';
import { getPolls, slugify } from '../../data/api';
import { sendEvent } from '../ui/Events';

export default function Hero() {
	const [loading, setLoading] = useState(true);
	const [item, setItem] = useState({
		attributes: {
			title: '',
			cover: {
				data: {
					attributes: {
						url: ''
					}
				}
			}
		},
		id: ''
	});

	const polls = async () => {
		const data = await getPolls({ limit: 1, start: 0, featured: true });
		setItem(data.data[0]);
		setLoading(false);
	};
	useEffect(() => {
		if (loading) {
			polls();
		}
	}, [loading]);
	if (loading) {
		return (
			<div className="center p-2">
				Loading...
			</div>
		);
	}

	const createEvent = () => {
		console.log('Eventing');
		sendEvent({
			event: 'click',
			title: item?.attributes?.title,
			type: 'poll',
			position: 'hero',
			id: item?.id
		});
	};

	return (
		<div className="overflow-hidden relative mb-2 blue p-2 mobile:phor mobile:pver-2 mobile:mb-0">
			<div className="grid2 max-w-1600px mhor-auto col-gap-2 mobile:grid1 mobile:row-gap mobile:max-w-100">
				<div className="flex align-center">
					<img alt="Home" src={item.attributes.cover.data.attributes.url} className="ratio-4-3 obj-cover w-100 br" />
				</div>
				<div className="flex align-center">
					<div>
						<div className="pver">
							<h3 className="f-medium f-600 t-blue f-uppercase mb-half t-white mobile:f-1rem">Poll of the day</h3>
							<div className="h-3px w-2rem red" />
						</div>
						<h1 className="t-white mobile:h4">
							{item?.attributes?.title}
						</h1>
						<div className="mt-2">
							<Button color="red" type="link" link={`/polls/${item.id}/${slugify(item?.attributes?.title)}`} onClick={createEvent}>
								Vote Now
							</Button>
						</div>
					</div>

				</div>
			</div>
		</div>
	);
}
