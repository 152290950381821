import React from 'react';

interface IconProps{
	className?: string;
	color?: string;
}

export default function IconArrowRight({ className, color }:IconProps) {
	return (
		<svg width="27" height="23" viewBox="0 0 27 23" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
			<path d="M26.8027 11.0879C26.8027 10.5046 26.6022 10.0169 26.2012 9.625L17.3008 0.738281C17.0911 0.528646 16.8678 0.373698 16.6309 0.273438C16.3939 0.164062 16.1523 0.109375 15.9062 0.109375C15.3229 0.109375 14.8535 0.291667 14.498 0.65625C14.1426 1.01172 13.9648 1.45378 13.9648 1.98242C13.9648 2.2832 14.0195 2.55208 14.1289 2.78906C14.2474 3.01693 14.3978 3.22201 14.5801 3.4043L17.6426 6.48047L22.168 10.6367L23.002 9.39258L17.6973 9.10547H2.23438C1.6237 9.10547 1.12695 9.28776 0.744141 9.65234C0.370443 10.0169 0.183594 10.4954 0.183594 11.0879C0.183594 11.6803 0.370443 12.1589 0.744141 12.5234C1.12695 12.888 1.6237 13.0703 2.23438 13.0703H17.6973L23.002 12.7832L22.168 11.5391L17.6426 15.6953L14.5801 18.7578C14.3978 18.9401 14.2474 19.1497 14.1289 19.3867C14.0195 19.6146 13.9648 19.8789 13.9648 20.1797C13.9648 20.7083 14.1426 21.1504 14.498 21.5059C14.8535 21.8704 15.3229 22.0527 15.9062 22.0527C16.4167 22.0527 16.877 21.8477 17.2871 21.4375L26.2012 12.5508C26.6022 12.1497 26.8027 11.6621 26.8027 11.0879Z" className={color || 'white'} />
		</svg>

	);
}
