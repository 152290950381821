import React from 'react';

interface InputProps {
	className?: string;
	label?: string;
	name: string;
	value?: string;
	placeholder?: string;
	onChange?: (e:any) => void;
	id: string;
}

export default function Input({
	className, label, placeholder, name, value, onChange, id }:InputProps) {
	return (
		<div className={`${className} relative mt`}>
			{label && <label className="label pver-half relative f-600 f-1rem mobile:f-small white inline-flex" htmlFor={id}>{label}</label>}
			<input
				className="w-100 h-4rem phor-2 pver f-medium mobile:f-1rem mobile:phor mobile:h-3rem b b-grey200 br-half"
				id={id}
				type="text"
				placeholder={placeholder}
				value={value}
				name={name}
				onChange={onChange}
			/>
		</div>
	);
}
