import React, { useEffect, useState } from 'react';
import { getPetitions } from '../../data/api';
import PetitionsListBox from './PetitionsListBox';
import IconChevronRight from '../ui/icons/IconChevronRight';

interface PetitionsFeedProps {
	limit?: number;
	start?: number;
	notEqual?: number;
}

export default function PetitionsSlider({ limit, start, notEqual }:PetitionsFeedProps) {
	const [loading, setLoading] = useState(true);
	const [current, setCurrent] = useState(0);
	const [items, setItems] = useState([{
		attributes: {
			title: '',
			createdAt: '',
			cover: {
				data: {
					attributes: {
						url: ''
					}
				}
			}
		},
		id: ''
	}]);

	const entries = async () => {
		const data = await getPetitions({ limit: limit || 5, start: start || 0, ne: notEqual });
		setItems(data.data);
		setLoading(false);
	};
	useEffect(() => {
		if (loading) {
			entries();
		}
	}, [loading]);
	if (loading) {
		return (
			<div className="center p-2">
				Loading...
			</div>
		);
	}
	const prev = () => {
		if (current > 0) {
			setCurrent(current - 1);
		}
	};
	const next = () => {
		if (current < 2) {
			setCurrent(current + 1);
		}
	};
	return (
		<div className="flex align-center">
			<button type="button" className="shrinks-0 b-0 blue w-2rem h-2rem align-center flex justify-center br-50 rotate180" onClick={prev}>
				<IconChevronRight color="white" />
			</button>
			<div className="grid mb-2 row-gap-2 mhor center">
				{current + 1}
				&nbsp;/ 3
				{items.map(({ attributes, id }, index) => (
					<PetitionsListBox
						key={id}
						cover={attributes?.cover?.data?.attributes?.url}
						title={attributes.title}
						date={attributes.createdAt}
						id={id}
						className={`left ${index === current ? 'block' : 'hidden'}`}
					/>
				))}
			</div>
			<button type="button" className="shrinks-0 b-0 blue w-2rem h-2rem align-center flex justify-center br-50" onClick={next}>
				<IconChevronRight color="white" />
			</button>
		</div>
	);
}
