import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { joinAtom } from '../../data/atoms';
import IconX from '../ui/icons/IconX';
import ProfileForm from '../profile/ProfileForm';
import SignInForm from '../profile/SignInForm';
import LegalNote from './LegalNote';

interface JoinProps{
	title: string;
	loginTitle: string;
}

export default function Join({ title, loginTitle }:JoinProps) {
	const [join, setJoin] = useRecoilState(joinAtom);
	const [signup, setSignup] = useState(true);
	const next = () => {
		setJoin(false);
	};
	return (
		<div className="fixed edges z-1000 flex align-start center justify-center overflow-x-scroll">
			<div className="fixed edges w-100 h-100 black80" onClick={() => setJoin(false)} />
			<div className="white br  max-w-700px w-100 pver-2 phor-4 center relative mobile:mhor mobile:phor mt">
				<div className="flex justify-end" onClick={() => setJoin(false)}>
					<button type="button" className="b-0 transparent cursor-pointer">
						<IconX />
					</button>
				</div>
				<h4 className="t-blue mb f-uppercase mobile:h5">
					{signup ? title : loginTitle}
				</h4>
				<p className="mobile:phor mb mobile:mb-half">
					To vote on polls and support petitions!
				</p>

				{signup
					? (
						<div>
							<ProfileForm action="Join Now" next={next} />
							<p className="mt-half flex justify-center">
								Already have an account?
								<div className="ml-quarter cursor-pointer t-red transparent b-0 f-1rem" onClick={() => setSignup(!signup)}>Sign In</div>
							</p>
						</div>
					) : (
						<div>
							<SignInForm action="Sign In" next={next} />
							<p className="mt-half flex justify-center">
								Don&lsquo;t have an account?
								<div className="ml-quarter cursor-pointer t-red transparent b-0 f-1rem" onClick={() => setSignup(!signup)}>Sign Up</div>
							</p>
						</div>
					)}

				<LegalNote />

			</div>
		</div>
	);
}
