import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import PollOptions from './PollOptions';
import Button from '../ui/forms/Button';
import IconArrowRight from '../ui/icons/IconArrowRight';
import { getPollVote, savePollVote } from '../../data/api';
import Error from '../ui/forms/Error';
import { emailAtom, userAtom } from '../../data/atoms';
import { sendSimpleEvent, sendVoteEvent } from '../ui/Events';
import JoinLogin from '../home/JoinLogin';
import Share from '../home/Share';

interface PollOptionItem {
	id: number;
	label: string;
}

declare let gtag: Function;

interface PollQuestionProps {
	options: [PollOptionItem];
	title: string;
	question: string;
	id: number;
	pid?: string;
	visible: number;
	index: number;
	setVisible: (value: number) => void;
	total: number;
	image: string;
}

export default function PollQuestion({
	question, options, title, id, visible, index, setVisible, total, pid, image }:PollQuestionProps) {
	let navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const [voted, setVoted] = useState(false);
	const [sel, setSel] = useState('');
	const [error, setError] = useState('');
	const [join, setJoin] = useState(false);
	const [email, setEmail] = useRecoilState(emailAtom);
	const user = useRecoilValue(userAtom);

	const checkVote = async () => {
		if (user?.id) {
			let r = await getPollVote(id, user?.id);
			if (r.data?.length > 0) {
				setVoted(true);
			} else {
				setVoted(false);
			}
		} else {
			setVoted(false);
		}
		setLoading(false);
	};
	useEffect(() => {
		if (loading) {
			checkVote();
		}
	}, [loading]);

	useEffect(() => {
		if (sel) {
			setError('');
		}
	}, [sel]);

	const saveAnswer = async (qid:string) => {
		setError('');
		if (!sel) {
			setError('Please select one option');
			return;
		}
		if (!email) {
			setJoin(true);
			sendSimpleEvent({ event: 'click', title: 'Join At Poll' });

			return;
		}
		const label = options.find((option) => option.id.toString() === sel);
		const data = {
			questionId: qid,
			question,
			answerId: sel.toString(),
			answer: label?.label?.toString(),
			user: user?.id,
			gender: user?.attributes?.gender,
			party: user?.attributes?.party,
			age: user?.attributes?.age,
			poll: pid,
			pollTitle: title
		};

		await savePollVote(data);

		let step = index + 1;
		sendVoteEvent({
			event: 'vote',
			question,
			id,
			step,
			answer: label?.label?.toString()
		});
		if (visible + 1 < total) {
			setVisible(visible + 1);
		} else {
			navigate(`/polls/${pid}/results`);
		}
	};

	const voteSelected = (qid:string) => {
		setSel(qid);
	};
	useEffect(() => {
		if (sel) {
			saveAnswer(id.toString());
			const opt = options.find((e) => e.id.toString() === sel);
			sendSimpleEvent({ event: 'clickOption', title: opt?.label });
			if (typeof window !== 'undefined') {
				window.gtag('event', 'click', {
					clickedOption: sel
				});
			}
		}
	}, [sel]);

	return (
		<div className={`p-3 ${visible === index ? 'block' : 'hidden'} mobile:pver mobile:phor-2 small-desk:p-2`} key={`poll${id}`}>
			<h2 className="center t-blue mb-2 mobile:h5 mobile:mb h3 medium-desk:mb">
				{question}
			</h2>

			{error && <Error error={error} />}

			{loading ? <div>Loading ...</div>
				: voted ? (
					<div className="center h5 pver-2 w-100 mhor-auto mobile:h6 mobile:pver mobile:max-w-300px">
						You already voted for this poll!
					</div>
				) : (
					<PollOptions
						setSelected={voteSelected}
						selected={sel}
						options={options}
					/>
				)}

			{total > 1 && <div className="mobile:h-8rem" />}

			<div className={`center ${total > 1 ? 'mt-2 mobile:bottom-4 mobile:p mobile:fixed mobile:left-0 mobile:right-0 white mobile:z-20 mobile:h-5rem' : 'mt mobile:p'}`}>
				{loading ? <div>Loading ...</div>
					: voted ? (
						<Button
							type="link"
							link={`/polls/${pid}/results`}
							color="red"
							height="h-4rem mobile:h-3rem"
							fontSize="h5 mobile:h6"
						>
							View Results
							<IconArrowRight className="ml-half mobile:h-1rem" />
						</Button>
					) : (
						<Button
							color="red"
							type="button"
							height="h-4rem mobile:h-3rem"
							fontSize="h5 mobile:h6"
							onClick={() => saveAnswer(id.toString())}
						>
							Vote now
							<IconArrowRight className="ml-half mobile:h-1rem" />
						</Button>
					)}
			</div>

			{join && (
				<JoinLogin
					title="Sign Up to Vote!"
					description="To see the poll results, please submit your email"
					close={() => setJoin(false)}
					next={() => saveAnswer(id.toString())}
				/>
			)}
		</div>
	);
}
