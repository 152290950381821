import React from 'react';

export default function LegalNote() {
	return (
		<p className="pt f-small op-80">
			By registering, I represent that I am at least 18 years of age,
			that I am not a resident of the European Union, and that I have
			read and understand that this site is not affiliated nor endorsed
			with a formal polling agency. I agree that the personal information
			I provide you with may be shared with third parties for other marketing
			purposes, and agree to the
			&nbsp;
			<a className="t-red" href="/privacy" target="_blank">Privacy Policy</a>
			, California Privacy Policy and
			&nbsp;
			<a className="t-red" href="/terms" target="_blank">Terms and Conditions</a>
			; and agree to receive email marketing from Political.com and its Marketing Partners.
		</p>
	);
}
