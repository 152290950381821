import React from 'react';
import { Link } from 'react-router-dom';
import IconChevronRight from '../ui/icons/IconChevronRight';

interface TitleProps {
	title?: string;
	link?: string;
}

export default function SectionTitle({ title, link }:TitleProps) {
	return (
		<div className="flex align-center">
			<div className="pver grows">
				<h3 className="f-medium f-600 t-blue f-uppercase mb-half mobile:f-1rem">{title}</h3>
				<div className="h-3px w-2rem red" />
			</div>
			{link
				&& (
					<Link to={`${link}`} className="t-blue flex align-center">
						View all
						<IconChevronRight className="ml-half op-50" color="blue" />
					</Link>
				)}

		</div>

	);
}
