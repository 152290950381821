import React, { useEffect, useState } from 'react';
import { DiscussionEmbed } from 'disqus-react';
import { useParams } from 'react-router-dom';
import { getEntry } from '../data/api';
import Markdown from '../components/ui/Markdown';

export default function DoNotSell() {
	const id = 3;

	const [loading, setLoading] = useState(true);
	const [item, setItem] = useState({
		attributes: {
			title: '',
			body: '',
			createdAt: '',
			description: '',
			cover: {
				data: {
					attributes: {
						url: ''
					}
				}
			}
		},
		id: ''
	});

	const entry = async () => {
		const data = await getEntry({ id, endpoint: 'pages' });
		console.log('PAG1', data.data);
		setItem(data.data);
		setLoading(false);
	};
	useEffect(() => {
		if (loading) {
			entry();
		}
	}, [loading]);
	if (loading) {
		return (
			<div className="center p-2">
				Loading...
			</div>
		);
	}

	return (
		<div className="max-w-940px mhor-auto pver col-gap-2 mobile:phor mobile:block">
			<div className="w-100">
				<h1 className="h2 t-blue mver-2 mobile:h5">
					{item.attributes.title}
				</h1>
				<div dangerouslySetInnerHTML={{ __html: item.attributes.body }} className="editor" />
			</div>
			{/* <div className="op-20 w-100 mobile:mver">
				AD
			</div> */}
		</div>
	);
}
