import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useParams } from 'react-router-dom';
import { DiscussionEmbed } from 'disqus-react';
import { getEntry } from '../data/api';
import PetitionBar from '../components/petitions/PetitionBar';
import { userAtom } from '../data/atoms';
import Signature from '../components/petitions/Signature';
import Ad from '../components/home/Ad';
import Share from '../components/home/Share';

export default function Petition() {
	const { id } = useParams();
	const [loading, setLoading] = useState(true);
	const user = useRecoilValue(userAtom);
	const [item, setItem] = useState({
		attributes: {
			title: '',
			intro: '',
			createdAt: '',
			goal: 0,
			description: '',
			cover: {
				data: {
					attributes: {
						url: ''
					}
				}
			}
		},
		id: ''
	});

	const entry = async () => {
		const data = await getEntry({ id, endpoint: 'petitions' });
		setItem(data.data);
		setLoading(false);
	};
	useEffect(() => {
		if (loading) {
			entry();
		}
	}, [loading]);
	if (loading) {
		return (
			<div className="center p-2">
				Loading...
			</div>
		);
	}

	return (
		<div className="max-w-1056px mhor-auto pver grid3 col-gap-2 mobile:block mobile:p">
			<div className="col-span2">
				<img src={item.attributes?.cover?.data?.attributes?.url || 'https://res.cloudinary.com/thepark/image/upload/v1659845544/placeholder_8152618af4.jpg?updated_at=2022-08-07T04:12:24.952Z'} alt={item.attributes.title} className="w-100 obj-cover" />
				<h1 className="h2 t-blue mver-2 mobile:h5">
					{item.attributes.title}
				</h1>
				<PetitionBar goal={item.attributes.goal} />

				<div className="mobile:block hidden">
					<Signature title={item.attributes.title} petitionId={id} userId={user?.id} />
				</div>

				<Share
					title={item.attributes.title}
					description={item.attributes.title}
					image={item.attributes?.cover?.data?.attributes?.url || 'https://res.cloudinary.com/thepark/image/upload/v1659845544/placeholder_8152618af4.jpg?updated_at=2022-08-07T04:12:24.952Z'}
					url={`https://www.political.com/petitions/${id}`}
				/>

				<div className="max-w-940px mhor-auto">
					<Ad position="petitionBar" />
				</div>

				<div dangerouslySetInnerHTML={{ __html: item.attributes.description }} className="editor" />

				<div className="max-w-940px mhor-auto">
					<Ad position="petitionInfo" />
				</div>

				<DiscussionEmbed
					shortname="politicalcom"
					config={
						{
							url: `https://www.political.com/petition/${item.id}`,
							identifier: item.id.toString(),
							title: item.attributes.title,
							language: 'en_US'
						}
					}
				/>
			</div>
			<div>
				<div className="sticky top-1">
					<div className="mobile:hidden block">
						<Signature title={item.attributes.title} petitionId={id} userId={user?.id} />
					</div>
					<div className="max-w-940px mhor-auto">
						<Ad position="petitionRight" />
					</div>
				</div>
			</div>

		</div>
	);
}
