import mixpanel from 'mixpanel-browser';

mixpanel.init('1171f9978e5679c60062fd1f781cab3e', { debug: true });

export function sendEvent({ event, id, title, type, position }) {
	mixpanel.track(
		event,
		{ title, type, position, id }
	);
	return true;
}

export function sendSimpleEvent({ event, title }) {
	mixpanel.track(
		event,
		{ title }
	);
	return true;
}

export function sendVoteEvent({ event, question, answer, id, step }) {
	mixpanel.track(
		event,
		{
			question,
			answer,
			id,
			step
		}
	);
	return true;
}

export function sendPetitionEvent({ event, title, id }) {
	mixpanel.track(
		event,
		{
			title,
			id
		}
	);
	return true;
}

export function identifyUser(u) {
	mixpanel.identify(u.id);
	console.log('MIX', u);
	mixpanel.people.set({
		age: u.attributes.age || '',
		gender: u.attributes.gender || '',
		party: u.attributes.party || ''
		// $email: u.attributes?.email,
		// name: `${u.attributes?.firstName} ${u.attributes?.lastName}`
	});
	return true;
}
