import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getPoll, getPollQuestions } from '../data/api';
import PollQuestion from '../components/polls/PollQuestion';
import IconChevronRight from '../components/ui/icons/IconChevronRight';
import Ad from '../components/home/Ad';

const flattenPoll = (polls:any) => {
	let poll = {
		id: polls.id,
		title: polls.attributes.title,
		cover: polls.attributes.cover?.data?.attributes?.url,
		questions: polls.attributes.questions
	};
	return poll;
};

interface Option {
	id: number;
	label: string;
}

interface Question {
	id: number;
	options: [Option];
	question: string;
}

interface Poll {
	id: number;
	title: string;
	cover: string;
	questions: [Question];
}

export default function PollNew() {
	const { id } = useParams();

	const [loading, setLoading] = useState(true);
	const [visible, setVisible] = useState(0);
	const [poll, setPoll] = useState<Poll>();
	const entry = async () => {
		const p = await getPoll({ id });
		setPoll(flattenPoll(p.data));
		setLoading(false);
	};
	useEffect(() => {
		if (loading) {
			entry();
		}
	}, [loading]);

	if (loading) {
		return (
			<div className="center p-2">
				Loading...
			</div>
		);
	}

	const next = () => {
		if (poll?.questions) {
			if (visible + 1 < poll?.questions.length) {
				setVisible(visible + 1);
			}
		}
	};
	const prev = () => {
		if (visible > 0) {
			setVisible(visible - 1);
		}
	};
	return (
		<div className="max-w-1056px mhor-auto pver">
			<div className="center phor mobile:phor-2 mobile:hidden small-desk:hidden">
				<img
					src={poll?.cover || 'https://res.cloudinary.com/thepark/image/upload/v1659845544/placeholder_8152618af4.jpg?updated_at=2022-08-07T04:12:24.952Z'}
					alt={poll?.title}
					className="br sh10-black10 w-100 max-w-400px obj-cover ratio-16-9 medium-desk:max-w-300px"
				/>
			</div>
			{poll?.questions.map(({ options, question, id: qid }, index) => (
				<PollQuestion
					title={poll?.title}
					question={question}
					options={options}
					id={qid}
					key={`pollq${qid}`}
					index={index}
					visible={visible}
					setVisible={setVisible}
					total={poll?.questions?.length}
					pid={id}
					image={poll?.cover || 'https://res.cloudinary.com/thepark/image/upload/v1659845544/placeholder_8152618af4.jpg'}
				/>
			))}

			{poll?.questions && poll?.questions?.length > 1 && (
				<div className="flex justify-center">
					<div className="flex align-center justify-center mobile:fixed mobile:bottom-0 mobile:left-0 mobile:right-0 white mobile:pb mobile:z-30 mobile:h-5rem">
						<div className="flex justify-center align-center">
							<button
								type="button"
								className="b-0 blue w-2rem h-2rem align-center flex justify-center br-50 rotate180"
								onClick={prev}
							>
								<IconChevronRight color="white" />
							</button>
							<p className="center t-blue mhor">
								{visible + 1}
								&nbsp;/&nbsp;
								{poll?.questions?.length}
							</p>
							<button
								type="button"
								className="b-0 blue w-2rem h-2rem align-center flex justify-center br-50"
								onClick={next}
							>
								<IconChevronRight color="white" />
							</button>
						</div>
					</div>
				</div>
			)}

			<div className="max-w-940px mhor-auto">
				<Ad position="poll" />
			</div>
		</div>
	);
}
