import React, { useEffect, useState } from 'react';
import { DiscussionEmbed } from 'disqus-react';
import { useParams } from 'react-router-dom';
import { getEntry } from '../data/api';
import Markdown from '../components/ui/Markdown';
import Ad from '../components/home/Ad';
import Share from '../components/home/Share';

export default function Article() {
	const { id } = useParams();

	const [loading, setLoading] = useState(true);
	const [item, setItem] = useState({
		attributes: {
			title: '',
			intro: '',
			createdAt: '',
			description: '',
			cover: {
				data: {
					attributes: {
						url: ''
					}
				}
			}
		},
		id: ''
	});

	const entry = async () => {
		const data = await getEntry({ id, endpoint: 'articles' });
		console.log('ART1', data.data);
		setItem(data.data);
		setLoading(false);
	};
	useEffect(() => {
		if (loading) {
			entry();
		}
	}, [loading]);
	if (loading) {
		return (
			<div className="center p-2">
				Loading...
			</div>
		);
	}

	return (
		<div className="max-w-1056px mhor-auto pver grid3 col-gap-2 phor mobile:block">
			<div className="col-span2">
				<img src={item.attributes?.cover?.data?.attributes?.url || 'https://res.cloudinary.com/thepark/image/upload/v1659845544/placeholder_8152618af4.jpg?updated_at=2022-08-07T04:12:24.952Z'} alt={item.attributes.title} className="w-100 ratio-16-9 obj-cover" />
				<h1 className="h2 t-blue mver-2 mobile:h5">
					{item.attributes.title}
				</h1>
				<Share
					title={item.attributes.title}
					description={item.attributes.title}
					image={item.attributes?.cover?.data?.attributes?.url || 'https://res.cloudinary.com/thepark/image/upload/v1659845544/placeholder_8152618af4.jpg?updated_at=2022-08-07T04:12:24.952Z'}
					url={`https://www.political.com/read/${id}`}
				/>
				<div dangerouslySetInnerHTML={{ __html: item.attributes.description }} className="editor" />
				<div className="max-w-940px mhor-auto">
					<Ad position="articleInfo" />
				</div>
				<DiscussionEmbed
					shortname="politicalcom"
					config={
						{
							url: `https://www.political.com/read/${item.id}`,
							identifier: item.id.toString(),
							title: item.attributes.title,
							language: 'en_US'
						}
					}
				/>

			</div>
			<div className="col-span">
				<div className="sticky top-1">
					<div className="max-w-940px mhor-auto">
						<Ad position="articleRight" />
					</div>
				</div>
			</div>

		</div>
	);
}
