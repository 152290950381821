import { atom } from 'recoil';

interface Attributes{
	email: string;
	firstName?: string;
	lastName?: string;
	state?:string;
	gender?:string;
	country?:string;
	newsletter?:boolean;
	party?:string;
	age?:string;
}

interface User {
	id: string;
	attributes: Attributes;
}

export const joinAtom = atom({
	key: 'JoinAtom',
	default: false
});

export const loadingPetitionBar = atom({
	key: 'LoadingPetitionBar',
	default: true
});

export const emailAtom = atom({
	key: 'EmailAtom',
	default: ''
});

export const userAtom = atom({
	key: 'UserAtom',
	default: null as User | null
});
