import React, { useEffect, useState } from 'react';
import { PaginationObj } from '../../../data/interfaces';
import { getTotalPollVotes } from '../../../data/api';

interface TotalVotesProps {
	id:string;
}

export default function TotalVotes({ id }:TotalVotesProps) {
	const [loading, setLoading] = useState(true);
	const [total, setTotal] = useState<PaginationObj>({
		total: 0
	});
	const entry = async () => {
		const data = await getTotalPollVotes({ id });
		console.log('TOTAL', data.meta);
		setTotal(data.meta.pagination);
		setLoading(false);
	};
	useEffect(() => {
		if (loading) {
			entry();
		}
	}, [loading]);
	return (
		<span>{total.total}</span>
	);
}
