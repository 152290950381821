import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getPoll, slugify } from '../data/api';
import OptionVotes from '../components/polls/results/OptionVotes';
import Ad from '../components/home/Ad';
import Share from '../components/home/Share';

const flattenPoll = (polls:any) => {
	let poll = {
		id: polls.id,
		title: polls.attributes.title,
		cover: polls.attributes.cover?.data?.attributes?.url,
		questions: polls.attributes.questions
	};
	return poll;
};

interface Option {
	id: number;
	label: string;
}

interface Question {
	id: number;
	options: [Option];
	question: string;
}

interface Poll {
	id: number;
	title: string;
	cover: string;
	questions: [Question];
}

export default function PollResults() {
	const { id } = useParams();

	const [loading, setLoading] = useState(true);
	const [poll, setPoll] = useState<Poll>();

	const entry = async () => {
		const p = await getPoll({ id });
		setPoll(flattenPoll(p.data));
		setLoading(false);
	};
	useEffect(() => {
		if (loading) {
			entry();
		}
	}, [loading]);

	if (loading) {
		return (
			<div className="center p-2">
				Loading...
			</div>
		);
	}
	return (
		<div className="mb">
			<div className="flex justify-center mobile:block align-center center mobile:mt">
				<b className="mr mobile:f-small mobile:mb-half">Share this poll</b>
				<Share
					title={poll?.title}
					description={poll?.title}
					image={poll?.cover || 'https://res.cloudinary.com/thepark/image/upload/v1659845544/placeholder_8152618af4.jpg?updated_at=2022-08-07T04:12:24.952Z'}
					url={`https://www.political.com/polls/${id}/${slugify(poll?.title)}`}
				/>
			</div>

			<div className="max-w-940px mhor-auto">
				<Ad position="pollResultsHeader" />
			</div>
			{poll?.questions?.map(({ question, id:	qid, options }) => (
				<OptionVotes
					key={`vote-results${qid}`}
					title={question}
					options={options}
					question={qid}
				/>
			))}
			<div className="max-w-940px mhor-auto">
				<Ad position="pollResultsFooter" />
			</div>
		</div>
	);
}
