import React from 'react';
import { useRecoilState } from 'recoil';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../ui/forms/Button';
import { emailAtom, joinAtom, userAtom } from '../../data/atoms';
import { sendSimpleEvent } from '../ui/Events';

export default function User() {
	let navigate = useNavigate();
	const [email, setEmail] = useRecoilState(emailAtom);
	const [join, setJoin] = useRecoilState(joinAtom);
	const [user, setUser] = useRecoilState(userAtom);
	const logout = () => {
		window.localStorage.clear();
		setEmail('');
		setUser(null);
		setJoin(false);
		navigate('/');
		sendSimpleEvent({ event: 'click', title: 'Logout' });
		sendSimpleEvent({ event: 'logout', title: '' });
	};

	return (
		<div className="flex align-center">
			<Link to="/profile" className="f-uppercase br-50 h-3rem w-3rem mobile:h-2rem mobile:w-2rem shrinks-0 red t-white flex align-center justify-center mr-half f-medium f-600" onClick={() => sendSimpleEvent({ event: 'click', title: 'Menu Item Profile' })}>
				{email.charAt(0)}
			</Link>
			<Button type="button" height="h-2rem" fontSize="f-small" color="grey300" onClick={logout}>
				Logout
			</Button>

		</div>
	);
}
