import React, { useEffect, useState } from 'react';
import { getArticles } from '../../data/api';
import ArticleListBox from './ArticleListBox';

interface ArticlesFeedProps {
	limit?: number;
	start?: number;
}

export default function ArticlesFeed({ limit, start }:ArticlesFeedProps) {
	const [loading, setLoading] = useState(true);
	const [items, setItems] = useState([{
		attributes: {
			title: '',
			intro: '',
			createdAt: '',
			cover: {
				data: {
					attributes: {
						url: ''
					}
				}
			}
		},
		id: ''
	}]);

	const polls = async () => {
		const data = await getArticles({ limit: limit || 2, start: start || 0 });
		setItems(data.data);
		setLoading(false);
	};
	useEffect(() => {
		if (loading) {
			polls();
		}
	}, [loading]);
	if (loading) {
		return (
			<div className="center p-2">
				Loading...
			</div>
		);
	}
	if (items.length < 1) {
		return (<div className="pver-2 center w-100"> No articles to dipslay yet </div>);
	}
	return (
		<div className="grid2 col-gap-2 mb-2 row-gap-2 mobile:block">
			{items.map(({ attributes, id }) => (
				<ArticleListBox
					key={id}
					cover={attributes?.cover?.data?.attributes?.url}
					title={attributes.title}
					date={attributes.createdAt}
					intro={attributes.intro}
					id={id}
				/>
			))}
		</div>
	);
}
