import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useParams } from 'react-router-dom';
import { getPetitionSignatures } from '../../data/api';
import { loadingPetitionBar } from '../../data/atoms';

interface Pagination {
	total: number;
}

interface Meta {
	pagination: Pagination;
}

interface Total {
	meta: Meta;
}

interface PetitionBarProps {
	goal?: number;
}

export default function PetitionBar({ goal }:PetitionBarProps) {
	const { id } = useParams();
	const [loadingBar, setLoadingBar] = useRecoilState(loadingPetitionBar);
	const [total, setTotal] = useState(0);
	const [goalBar, setGoal] = useState(goal || 0);
	const loadData = async () => {
		console.log('Loading bar');
		const res:Total = await getPetitionSignatures({ id });
		setLoadingBar(false);
		console.log('PS', res.meta.pagination.total);
		setTotal(res.meta.pagination.total);
	};
	useEffect(() => {
		if (loadingBar) {
			loadData();
		}
	}, [loadingBar]);
	return (
		<div className="mb-2">
			<div className="grey100 h-2rem br mb">
				<div className="red h-2rem br mb t-5" style={{ width: `${(total / goalBar) * 100}%` }} />
			</div>
			<div className="flex align-center">
				<div className="grows">
					<strong className="f-600">{total}</strong>
					&nbsp; Supporters
				</div>
				<div>
					<strong className="f-600">{goal}</strong>
					&nbsp; Goal
				</div>
			</div>
		</div>

	);
}
