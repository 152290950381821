import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import Input from '../ui/forms/Input';
import Genders from './Genders';
import Age from './Age';
import Parties from './Parties';
import Error from '../ui/forms/Error';
import Button from '../ui/forms/Button';
import { emailAtom, loadingPetitionBar, userAtom } from '../../data/atoms';
import { getSoftUser, saveSoftUser, updateSoftUser } from '../../data/api';
import { identifyUser } from '../ui/Events';

interface FormProps{
	next: () => void;
	action: string;
}

export default function SignInForm({ next, action }:FormProps) {
	const localemail = window.localStorage.getItem('email');
	let navigate = useNavigate();
	const [error, setError] = useState('');
	const [emailLocal, setEmailLocal] = useRecoilState(emailAtom);
	const [email, setEmail] = useState(emailLocal);
	const [userLocal, setUserLocal] = useRecoilState(userAtom);
	const [loading, setLoading] = useRecoilState(loadingPetitionBar);

	const [user, setUser] = useState({
		firstName: userLocal?.attributes?.firstName || '',
		lastName: userLocal?.attributes?.lastName || '',
		gender: userLocal?.attributes?.gender || '',
		age: userLocal?.attributes?.age || '',
		party: userLocal?.attributes?.party || ''
	});

	const getUser = async () => {
		if (localemail) {
			let u = await getSoftUser({ email: localemail?.toLowerCase() });
			if (u.data.length > 0) {
				setUser(u.data[0].attributes);
			}
		}
	};

	const validateEmail = (em:string) => {
		const eml = String(em).toLowerCase();
		return eml.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
	};
	const saveProfile = async () => {
		setError('');
		if (!email) {
			setError('Please enter an email to continue');
			return;
		}
		if (!validateEmail(email)) {
			setError('Please enter a valid email');
			return;
		}
		let u = await getSoftUser({ email });
		if (u.data.length > 0) {
			console.log('Exists', u.data);
			window.localStorage.setItem('email', email);
			setUserLocal(u.data[0]);
			if (emailLocal === email) {
				console.log('next occurs in effect');
			} else {
				setEmailLocal(email);
			}
			identifyUser({
				id: userLocal?.id,
				attributes: { ...user }
			});
		} else {
			setError('Invalid user, please make review your email or signup');
		}
	};

	const save = async () => {
		console.log('Saving');
		await saveProfile();
		console.log('Passing to next step');
	};

	useEffect(() => {
		// setEmail(emailLocal);
		console.log('Email set on signup', emailLocal);
		if (emailLocal) {
			next();
		}
	}, [emailLocal]);
	useEffect(() => {
		// setEmail(emailLocal);
		console.log('Updated User Local', emailLocal);
		if (emailLocal && emailLocal === email) {
			console.log('Updated User Local & email was already set');
			next();
		}
	}, [userLocal]);
	useEffect(() => {
		if (loading) {
			getUser();
			setLoading(false);
		}
	}, [loading]);

	return (
		<div className="left">
			<div className="mb grid mobile:mb-half">
				<Input name="email" id="emailP" placeholder="Email" label="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
			</div>
			{error && <Error error={error} />}
			<div className="mt-2 mobile:mt center">
				<Button type="button" color="red" onClick={save}>
					{action}
				</Button>
			</div>
		</div>
	);
}
