import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import Logo from '../ui/icons/Logo';
import NavMenu from './NavMenu';
import Button from '../ui/forms/Button';
import { getParties, getSoftUser } from '../../data/api';
import { emailAtom, joinAtom, userAtom } from '../../data/atoms';
import Join from '../home/Join';
import User from '../home/User';
import { identifyUser, sendSimpleEvent } from '../ui/Events';

export default function Header() {
	const localemail = window.localStorage.getItem('email');
	const [email, setEmail] = useRecoilState(emailAtom);
	const [loading, setLoading] = useState(true);
	const [user, setUser] = useRecoilState(userAtom);
	const [join, setJoin] = useRecoilState(joinAtom);

	const getUser = async () => {
		if (localemail) {
			setEmail(localemail);
			let u = await getSoftUser({ email: localemail.toLowerCase() });
			if (u.data.length > 0) {
				setUser(u.data[0]);
				console.log('UU', u.data[0]);
				identifyUser(u.data[0]);
			}
		}
		setLoading(false);
	};
	useEffect(() => {
		if (loading) {
			getUser();
			getParties();
		}
	}, [loading]);
	const clickJoin = () => {
		console.log('Join clicked');
		setJoin(!join);
		sendSimpleEvent({
			event: 'click',
			title: 'Join Button'
		});
	};

	return (
		<header className="flex p align-center mobile:block mobile:p-0">
			<div className="grows mobile:p mobile:center">
				<Link to="/">
					<Logo className="mobile:h-3rem" />
				</Link>
			</div>
			<div className="flex align-center mobile:justify-center mobile:grey100 mobile:pver-half">
				<NavMenu />
				{localemail
					? (
						<User />
					) : (
						<div>
							<Button type="button" height="h-2rem mobile:h-2rem" fontSize="mobile:f-small" onClick={clickJoin}>
								Join
							</Button>
							{join && <Join title="Sign Up" loginTitle="Sign in to your account!" />}
						</div>
					)}
			</div>
		</header>
	);
}
