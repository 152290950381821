import React from 'react';
import { Link } from 'react-router-dom';
import { InlineFollowButtons } from 'sharethis-reactjs';
import LogoFooter from '../ui/icons/LogoFooter';
import ShareSite from './ShareSite';

export default function Footer() {
	let paths = window.location.pathname.split('/');

	if (paths.length > 1 && paths[1].includes('polls')) {
		return (
			<footer />
		);
	}
	return (
		<footer className="max-w-1056px w-100 mhor-auto flex align-center pver-3 mobile:block mobile:p phor">
			<LogoFooter />
			<div className="grows flex justify-end mobile:block">
				<div>
					Copyright 2022
					<span className="f-700 t-blue inline-flex mhor">Political.com</span>
					<div className="op-60 mobile:pver mobile:f-small">
						<Link to="/privacy" className="t-blue">Privacy Policy </Link>
						&nbsp;
						|
						&nbsp;
						<Link to="/terms" className="t-blue">Terms & Conditions </Link>
						&nbsp;
						|
						&nbsp;
						<Link to="/unsubscribe" className="t-blue">Unsubscribe</Link>
						&nbsp;
						|
						&nbsp;
						<a href="mailto:support@political.com" className="t-blue">Contact Us</a>
						&nbsp;
						|
						&nbsp;
						<Link to="/do-not-sell" className="t-blue">Do not sell my info</Link>
					</div>
					<div className="mt">
						<ShareSite />
					</div>

				</div>

			</div>

		</footer>
	);
}
