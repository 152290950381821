import React from 'react';
import SectionTitle from '../components/layout/SectionTitle';
import PetitionsFeed from '../components/petitions/PetitionsFeed';
import Ad from '../components/home/Ad';

export default function Petitions() {
	return (
		<div className="max-w-1056px mhor-auto pb-2 b-bottom mb phor" style={{ borderColor: '#e7e7e7' }}>
			<div className="max-w-940px mhor-auto">
				<Ad position="petitionsHeader" />
			</div>
			<SectionTitle title="Petitions" />
			<div className="grid row-gap-2">
				<PetitionsFeed limit={100} />
			</div>
			<div className="max-w-940px mhor-auto">
				<Ad position="petitionsFeed" />
			</div>
		</div>
	);
}
