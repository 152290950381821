import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../ui/forms/Button';
import { sendEvent } from '../ui/Events';
import { slugify } from '../../data/api';

interface PollProps{
	id: string
	title: string;
	cover: string;
}

export default function ({ title, id, cover }:PollProps) {
	const createEvent = () => {
		console.log('Eventing');
		sendEvent({
			event: 'click',
			title,
			id,
			type: 'poll',
			position: 'feed'
		});
	};
	return (
		<Link className="flex w-100" to={`/polls/${id}/${slugify(title)}`} onClick={createEvent}>
			<div className="relative flex align-center mobile:w-100 mobile:mb grows">
				<img className="br absolute w-100 h-100 obj-cover" src={cover || 'https://res.cloudinary.com/thepark/image/upload/v1659845544/placeholder_8152618af4.jpg?updated_at=2022-08-07T04:12:24.952Z'} alt="Some" />
				<div className="br absolute w-100 h-100 black op-60" />
				<div className="relative p-2">
					<h3 className="center t-white mb mobile:h4 mobile:mb-0">
						{title}
					</h3>
					<div id={id} className="center pver">
						<Button type="button">
							VOTE NOW
						</Button>
					</div>

				</div>

			</div>
		</Link>
	);
}
