import React from 'react';
import SectionTitle from '../components/layout/SectionTitle';
import ArticlesFeed from '../components/articles/ArticlesFeed';
import Ad from '../components/home/Ad';

export default function Articles() {
	return (
		<div className="max-w-1056px mhor-auto pb-2 phor" style={{ borderColor: '#e7e7e7' }}>
			<div className="max-w-940px mhor-auto">
				<Ad position="articlesHeader" />
			</div>
			<SectionTitle title="Read" />
			<ArticlesFeed />
			<div className="max-w-940px mhor-auto">
				<Ad position="articlesFeed" />
			</div>
		</div>
	);
}
