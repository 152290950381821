import React from 'react';
import styled from 'styled-components';

interface PollOptionProps{
	id: number,
	label: string,
	selected?: string,
	setSelected: (id: string) => void;
}

const Option = styled.div`
  padding: 2rem 1rem;
  border-radius: 5px;
  margin: auto 0.5rem;
  color: black;
  // box-shadow: 0 0 10px rgba(0,0,0,0.1);
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 600;
  cursor: pointer;
  &:hover{
    // box-shadow: 0 0 30px rgba(0,0,0,0.2);
	cursor: pointer;
  }
  @media (max-width: 640px) {
    padding: 0.5rem 1rem;
	margin-bottom: 0.5rem;
    font-size: 1.4rem;
  }
`;

const Circle = styled.div`
  box-shadow: 0 0 3px rgba(0,0,0,0.3);
  &:hover{
  }
`;

export default function PollOption({ id, label, selected, setSelected }:PollOptionProps) {
	return (
		<Option onClick={() => setSelected(id.toString())}>
			<Circle className="white w-2rem h-2rem p-half br-50 mr-half">
				<div className={`w-1rem br-50 h-1rem ${id.toString() === selected ? 'red' : 'grey200 hover:grey400'}`} />
			</Circle>
			{label}
		</Option>
	);
}
