import axios from 'axios';

const url = 'https://political-appi.herokuapp.com/api/';

export const slugify = (str) => str
	.toLowerCase()
	.trim()
	.replace(/[^\w\s-]/g, '')
	.replace(/[\s_-]+/g, '-')
	.replace(/^-+|-+$/g, '');

export const getGenders = async () => {
	try {
		const res = await axios.get(
			`${url}genders`,
			{
				params: {
					sort: 'label'
				},
				withCredentials: true
			}
		);
		return (res.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};

export const getParties = async () => {
	try {
		const res = await axios.get(
			`${url}parties`,
			{
				params: {
					sort: 'createdAt'
				},
				withCredentials: true
			}
		);
		return (res.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};

export const getAge = async () => {
	try {
		const res = await axios.get(
			`${url}ages`,
			{
				params: {
					sort: 'label'
				},
				withCredentials: true
			}
		);
		return (res.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};

export const getPolls = async ({ limit, start, featured }) => {
	let params = {
		sort: 'createdAt:desc',
		'populate[questions][populate]': '*',
		'populate[cover][fields][0]': 'caption',
		'populate[cover][fields][1]': 'url',
		'pagination[limit]': limit || 3,
		'pagination[start]': start || 0
	};
	if (featured) {
		params['filters[pollOfTheDay]'] = true;
	}
	try {
		const res = await axios.get(
			`${url}polls`,
			{
				params,
				withCredentials: true
			}
		);
		return (res.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};

export const getPoll = async ({ id }) => {
	try {
		const res = await axios.get(
			`${url}polls/${id}`,
			{
				params:
					{
						'populate[questions][populate]': '*',
						'populate[cover][fields][0]': 'caption',
						'populate[cover][fields][1]': 'url'
					},
				withCredentials: true
			}
		);
		return (res.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};

export const getPetitions = async ({ limit, start, ne }) => {
	let params = {
		populate: '*',
		sort: 'createdAt:desc',
		'pagination[limit]': limit || 3,
		'pagination[start]': start || 0
	};

	if (ne > -1) {
		params['filters[id][$ne]'] = ne;
	}

	try {
		const res = await axios.get(
			`${url}petitions`,
			{
				params,
				withCredentials: true
			}
		);
		return (res.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};

export const getArticles = async ({ limit, start }) => {
	try {
		const res = await axios.get(
			`${url}articles`,
			{
				params: {
					populate: '*',
					sort: 'createdAt:desc',
					'pagination[limit]': limit || 3,
					'pagination[start]': start || 0
				},
				withCredentials: true
			}
		);
		return (res.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};

export const getEntry = async ({ id, endpoint }) => {
	try {
		const res = await axios.get(
			`${url}${endpoint}/${id}`,
			{
				params: {
					populate: '*'
				},
				withCredentials: true
			}
		);
		return (res.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};

export const getPetitionSignature = async (id, user) => {
	try {
		const res = await axios.get(
			`${url}petition-signatures`,
			{
				params:
					{
						'filters[petition]': id,
						'filters[user]': user
					},
				withCredentials: true
			}
		);
		return (res.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};

export const getAds = async (position) => {
	try {
		const res = await axios.get(
			`${url}ads`,
			{
				params:
					{
						'filters[position]': position,
						'filters[active]': true,
						populate: '*',
						sort: 'createdAt:asc',
						'pagination[limit]': 1
					},
				withCredentials: true
			}
		);
		return (res.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};

export const getPollQuestions = async ({ id }) => {
	try {
		const res = await axios.get(
			`${url}poll-questions`,
			{
				params:
					{
						populate: '*',
						sort: 'createdAt:asc',
						'filters[poll]': id
					},
				withCredentials: true
			}
		);
		return (res.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};

export const getPollVotes = async ({ id }) => {
	try {
		const res = await axios.get(
			`${url}poll-votes`,
			{
				params:
					{
						populate: '*',
						sort: 'createdAt:asc',
						'filters[answer][$notNull]': true,
						'pagination[limit]': 1000,
						'filters[questionId]': id
					},
				withCredentials: true
			}
		);
		return (res.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};

export const getTotalPollVotes = async ({ id }) => {
	try {
		const res = await axios.get(
			`${url}poll-votes`,
			{
				params:
					{
						populate: '*',
						sort: 'createdAt:asc',
						'filters[answer][$notNull]': true,
						'pagination[limit]': 1000,
						'filters[poll]': id
					},
				withCredentials: true
			}
		);
		return (res.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};

export const getPetitionSignatures = async ({ id }) => {
	try {
		const res = await axios.get(
			`${url}petition-signatures`,
			{
				params:
					{
						'filters[petition]': id
					},
				withCredentials: true
			}
		);
		return (res.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};

export const getSoftUser = async ({ email }) => {
	try {
		const res = await axios.get(
			`${url}soft-users`,
			{
				params:
					{
						populate: '*',
						sort: 'createdAt:asc',
						'filters[email]': email,
						'pagination[limit]': 1
					},
				withCredentials: true
			}
		);
		return (res.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};

export const saveSoftUser = async (data) => {
	try {
		const res = await axios.post(
			`${url}soft-users`,
			{ data }
		);
		return (res.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};

export const savePollVote = async (data) => {
	console.log('VOTE', data);
	try {
		const res = await axios.post(
			`${url}poll-votes`,
			{ data }
		);
		return (res.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};

export const getPollVote = async (id, user) => {
	try {
		const res = await axios.get(
			`${url}poll-votes`,
			{
				params:
					{
						sort: 'createdAt:asc',
						'filters[user]': user,
						'filters[questionId]': id
					},
				withCredentials: true
			}
		);
		return (res.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};

export const savePetitionSignature = async (data) => {
	try {
		const res = await axios.post(
			`${url}petition-signatures`,
			{ data }
		);
		return (res.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};

export const updateSoftUser = async (id, data) => {
	try {
		const res = await axios.put(
			`${url}soft-users/${id}`,
			{ data }
		);
		return (res.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};

export const addEmail = async (data) => {
	try {
		const res = await axios.post(
			'https://www.political.com/email',
			{ ...data }
		);
		return (res.data);
	} catch (e) {
		console.log(e);
		throw e;
	}
};
