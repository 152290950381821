import React from 'react';

interface ErrorProps {
	error: string;
}

export default function Error({ error }:ErrorProps) {
	return (
		<p className="center t-red mt">{error}</p>
	);
}
