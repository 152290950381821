import React from 'react';

interface IconProps{
	className?: string;
	color?: string;
}

export default function IconX({ className, color }:IconProps) {
	return (
		<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
			<path d="M1.00974 15.4492C1.12693 15.5599 1.26039 15.6348 1.41013 15.6738C1.55987 15.7129 1.70961 15.7129 1.85935 15.6738C2.00909 15.6348 2.1393 15.5599 2.24998 15.4492L8.49998 9.19922L14.75 15.4492C14.8607 15.5599 14.9909 15.6348 15.1406 15.6738C15.2903 15.7129 15.4401 15.7129 15.5898 15.6738C15.7461 15.6413 15.8795 15.5664 15.9902 15.4492C16.1009 15.3385 16.1725 15.2083 16.2051 15.0586C16.2441 14.9089 16.2441 14.7591 16.2051 14.6094C16.1725 14.4596 16.1009 14.3294 15.9902 14.2188L9.74021 7.95898L15.9902 1.70898C16.1009 1.59831 16.1758 1.4681 16.2148 1.31836C16.2539 1.16862 16.2539 1.01888 16.2148 0.869141C16.1758 0.719401 16.1009 0.589193 15.9902 0.478516C15.873 0.361328 15.7396 0.286458 15.5898 0.253906C15.4401 0.214844 15.2903 0.214844 15.1406 0.253906C14.9909 0.286458 14.8607 0.361328 14.75 0.478516L8.49998 6.72852L2.24998 0.478516C2.1393 0.361328 2.00584 0.286458 1.84959 0.253906C1.69985 0.214844 1.55011 0.214844 1.40037 0.253906C1.25063 0.286458 1.12042 0.361328 1.00974 0.478516C0.899066 0.589193 0.824197 0.719401 0.785134 0.869141C0.752582 1.01888 0.752582 1.16862 0.785134 1.31836C0.824197 1.4681 0.899066 1.59831 1.00974 1.70898L7.25974 7.95898L1.00974 14.2188C0.899066 14.3294 0.824197 14.4596 0.785134 14.6094C0.746072 14.7591 0.742816 14.9089 0.775368 15.0586C0.814431 15.2083 0.892556 15.3385 1.00974 15.4492Z" className={color || 'black'} />
		</svg>
	);
}
