import React, { useEffect, useState } from 'react';
import AdSense from 'react-adsense';
import { getAds } from '../../data/api';

export default function Ad({ position }) {
	const [loading, setLoading] = useState(true);
	const [ad, setad] = useState();
	const get = async () => {
		let r = await getAds(position);
		if (r.data.length > 0) {
			setad(r.data[0]);
		}
		setLoading(false);
	};
	useEffect(() => {
		get();
	}, [loading]);

	return (
		<div>
			{ad?.id && (
				<div>
					{ad?.attributes.adsenseSlot ? (
						<div className="mver">
							<AdSense.Google
								client="ca-pub-9612622857103750"
								slot={ad?.attributes?.adsenseSlot}
							/>
						</div>

					) : (
						<div className="w-100 mver flex align-center justify-center">
							<a href={ad.attributes?.link} target="_blank" rel="noreferrer">
								<img src={ad.attributes?.banner?.data?.attributes?.url} alt={ad.attributes.title} />
							</a>
						</div>
					)}
				</div>
			)}
		</div>
	);
}
