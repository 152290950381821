import React, { useEffect, useState } from 'react';
import { getPollVotes } from '../../../data/api';
import { OptionObj, PaginationObj, PollVotesObj } from '../../../data/interfaces';
import BarVertical from './BarVertical';
import GenderVotes from './GenderVotes';
import AgeVotes from './AgeVotes';
import PartyVotes from './PartyVotes';
import Ad from '../../home/Ad';

interface OptionVotesProps {
	question: number;
	title: string;
	options: OptionObj[];
}

export default function OptionVotes({ question, title, options }:OptionVotesProps) {
	const [loading, setLoading] = useState(true);
	const [total, setTotal] = useState<PaginationObj>({
		total: 0
	});
	const [votes, setVotes] = useState<PollVotesObj[]>([]);
	const entry = async () => {
		const data = await getPollVotes({ id: question });
		setVotes(data.data);
		setTotal(data.meta.pagination);
		setLoading(false);
	};
	useEffect(() => {
		if (loading) {
			entry();
		}
	}, [loading]);
	votes.forEach((e) => console.log('GEN', e.attributes.gender, e.attributes.answer));
	if (loading) {
		return (
			<div className="center p-2">
				Loading...
			</div>
		);
	}
	return (
		<div className="blue mb phor">
			<div className="max-w-1056px mhor-auto pver grid2 mobile:block pver-4 mobile:pb-2">
				<div className="flex align-center">
					<div className="mobile:mb mobile:center">
						<h3 className="t-white mb pr-2 mobile:p-0 mobile:h4">
							{title}
						</h3>
						<h5 className="f-uppercase f-400 t-white mobile:h5">
							Total votes:
							&nbsp;
							{total.total}
						</h5>
					</div>
				</div>
				<div className={`flex t-white mobile:justify-center mobile:mt mobile:grid${options.length}`}>
					{options.map(({ id, label }) => {
						const v = votes.filter((vote) => vote.attributes.answer === label);
						const value = v.length;
						return (
							<BarVertical
								key={`vote-bar-${id}`}
								label={label}
								total={total.total}
								value={value}
							/>
						);
					})}
				</div>
			</div>
			<div className="max-w-940px mhor-auto">
				<Ad position="pollResultsMiddle" />
			</div>
			<div className="max-w-1056px mhor-auto pver-2">
				<div className="grid2 col-gap-2 mb-2 mobile:block">
					<div className="mobile:mb-2">
						<GenderVotes options={options} votes={votes} />
					</div>
					<AgeVotes options={options} votes={votes} />
				</div>
				<PartyVotes options={options} votes={votes} />
			</div>
		</div>
	);
}
