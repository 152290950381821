import React, { useState } from 'react';
import PollOption from './PollOption';

interface PollOptionItem {
	id: number;
	label: string;
}

interface PollOptionsProps{
	options: PollOptionItem[];
	setSelected: (id: string) => void;
	selected?: string;
}

export default function PollOptions({ options, setSelected, selected }:PollOptionsProps) {
	return (
		<div className="mobile:flex mobile:justify-center">
			<div className="flex justify-center align-center mobile:wrap mobile:justify-start mobile:inline-flex">
				{options.map(({ id, label }) => (
					<PollOption key={`option-${id}`} label={label} id={id} selected={selected} setSelected={setSelected} />
				))}
			</div>
		</div>
	);
}
