import React from 'react';
import { Link } from 'react-router-dom';
import { sendEvent } from '../ui/Events';
import { slugify } from '../../data/api';

interface ArticleProps{
	id: string
	title: string;
	cover?: string;
	intro?: string;
	date: string;
}

export default function ArticleListBox({
	cover, id, title, date, intro
}:ArticleProps) {
	const createEvent = () => {
		sendEvent({
			event: 'click',
			title,
			id,
			type: 'article',
			position: 'feed'
		});
	};
	return (
		<Link to={`/read/${id}/${slugify(title)}`} onClick={createEvent}>
			<div className="relative align-center ratio-16-9 center mobile:mb">
				<img className="br w-100 h-100 obj-cover" src={cover || 'https://res.cloudinary.com/thepark/image/upload/v1659845544/placeholder_8152618af4.jpg?updated_at=2022-08-07T04:12:24.952Z'} alt="Some" />
				<div className="relative" id={id}>
					<h4 className="h5 t-blue f-700 mb-half mt">
						{title}
					</h4>
					<p className="t-black op-60">
						{intro}
					</p>
				</div>
			</div>
		</Link>
	);
}
