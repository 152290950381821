import React, { useEffect, useState } from 'react';
import { OptionObj, PollVotesObj } from '../../../data/interfaces';
import { getParties } from '../../../data/api';
import HorizontalStack from './HorizontalStack';

interface GenderAttr {
	label: string;
}

interface GenderItems {
	attributes: GenderAttr;
	id: number;
}

interface GenderVotesProps {
	options: OptionObj[];
	votes: PollVotesObj[];
}

export default function PartyVotes({ options, votes }:GenderVotesProps) {
	const [loading, setLoading] = useState(true);
	const [genders, setGenders] = useState<GenderItems[]>([]);
	const getEntries = async () => {
		let r = await getParties();
		setGenders(r.data);
	};
	useEffect(() => {
		getEntries();
	}, [loading]);
	return (
		<div className="white p-2 br-half">
			<h5>Party</h5>
			{genders.length > 1 && genders.map(({ id, attributes: { label: gender } }) => {
				let filter = votes.filter((e) => e.attributes.party === gender);
				return (
					<div>
						<HorizontalStack key={gender} options={options} votes={filter} attributeValue={gender} attribute="party" />
					</div>
				);
			})}
		</div>
	);
}
