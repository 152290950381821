import React from 'react';

interface LogoProps{
  className?: string;
}

export default function LogoFooter({ className }:LogoProps) {
	return (
		<svg className={className} width="120" height="33" viewBox="0 0 120 33" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M89.7095 32.7123H91.9583V30.5753H89.7095V32.7123Z" fill="#D11111" />
			<path d="M96.6444 32.9359C98.5329 32.9359 99.9741 31.7059 100.235 29.892H97.9614C97.775 30.4884 97.2656 30.8735 96.6444 30.8735C95.8369 30.8735 95.2529 30.2399 95.2529 29.3577C95.2529 28.4881 95.8369 27.8668 96.6444 27.8668C97.2532 27.8668 97.7626 28.2271 97.949 28.7738H100.21C99.9493 26.9847 98.5453 25.8044 96.6569 25.8044C94.4826 25.8044 92.942 27.2829 92.942 29.3577C92.942 31.4326 94.495 32.9359 96.6444 32.9359Z" fill="#D11111" />
			<path d="M104.593 32.9359C106.779 32.9359 108.357 31.4326 108.357 29.3577C108.357 27.2829 106.779 25.792 104.593 25.792C102.406 25.792 100.841 27.2829 100.841 29.3577C100.841 31.4326 102.406 32.9359 104.593 32.9359ZM104.593 30.8611C103.76 30.8611 103.152 30.2523 103.152 29.3702C103.152 28.4756 103.76 27.8668 104.593 27.8668C105.438 27.8668 106.046 28.4881 106.046 29.3702C106.046 30.2523 105.438 30.8611 104.593 30.8611Z" fill="#D11111" />
			<path d="M117.33 25.8168C116.497 25.8168 115.752 26.1896 115.267 26.7984C114.845 26.1771 114.161 25.8168 113.341 25.8168C112.571 25.8168 111.863 26.1647 111.378 26.7362L111.055 26.0032H109.428V32.7123H111.739V29.0347C111.739 28.2023 112.087 27.755 112.708 27.755C113.192 27.755 113.453 28.1153 113.453 28.7987V32.7123H115.702V29.0347C115.702 28.1899 116.025 27.755 116.659 27.755C117.156 27.755 117.404 28.0905 117.404 28.7987V32.7123H119.715V28.5129C119.715 26.935 118.771 25.8168 117.33 25.8168Z" fill="#D11111" />
			<path d="M10.6167 6.69873C8.59773 6.69873 6.82727 7.38206 5.4606 8.56237L4.74621 7.22676H0.801514V30.0563H6.51667V23.4715C7.69697 24.1859 9.0947 24.5896 10.6167 24.5896C15.7417 24.5896 19.4068 20.8624 19.4068 15.6131C19.4068 10.3949 15.7417 6.69873 10.6167 6.69873ZM9.84015 19.4646C7.66591 19.4646 6.08182 17.8184 6.08182 15.6131C6.08182 13.4389 7.66591 11.7927 9.84015 11.7927C11.9833 11.7927 13.5985 13.4389 13.5985 15.6131C13.5985 17.8184 11.9833 19.4646 9.84015 19.4646Z" fill="#2A1F71" />
			<path d="M29.0978 24.5586C34.5645 24.5586 38.5092 20.8002 38.5092 15.6131C38.5092 10.426 34.5645 6.69873 29.0978 6.69873C23.6312 6.69873 19.7175 10.426 19.7175 15.6131C19.7175 20.8002 23.6312 24.5586 29.0978 24.5586ZM29.0978 19.3715C27.0168 19.3715 25.4948 17.8495 25.4948 15.6442C25.4948 13.4078 27.0168 11.8859 29.0978 11.8859C31.21 11.8859 32.7319 13.4389 32.7319 15.6442C32.7319 17.8495 31.21 19.3715 29.0978 19.3715Z" fill="#2A1F71" />
			<path d="M39.3167 23.9995H45.0939V1.16992H39.3167V23.9995Z" fill="#2A1F71" />
			<path d="M113.862 23.9995H119.639V1.16992H113.862V23.9995Z" fill="#2A1F71" />
			<path d="M48.0447 5.14586H53.7599V0.238281H48.0447V5.14586ZM48.0137 23.9996H53.7909V7.22692H48.0137V23.9996Z" fill="#2A1F71" />
			<path d="M67.9234 5.14586H73.6385V0.238281H67.9234V5.14586ZM67.8923 23.9996H73.6696V7.22692H67.8923V23.9996Z" fill="#2A1F71" />
			<path d="M64.5689 18.968C63.3575 18.968 62.612 18.2226 62.612 17.0423V11.6998H66.681V7.2271H62.4878V2.35059H61.2764L53.7598 10.3332V11.6998H56.8348V18.0051C56.8348 21.7324 59.1022 23.9998 62.7984 23.9998H66.7742V18.968H64.5689Z" fill="#2A1F71" />
			<path d="M84.8825 24.5588C89.6037 24.5588 93.2068 21.4838 93.859 16.9489H88.175C87.709 18.4398 86.4356 19.4027 84.8825 19.4027C82.8636 19.4027 81.4037 17.8186 81.4037 15.6133C81.4037 13.4391 82.8636 11.886 84.8825 11.886C86.4045 11.886 87.678 12.7868 88.1439 14.1535H93.7969C93.1446 9.68074 89.6348 6.72998 84.9136 6.72998C79.478 6.72998 75.6265 10.4262 75.6265 15.6133C75.6265 20.8004 79.509 24.5588 84.8825 24.5588Z" fill="#2A1F71" />
			<path d="M108.147 7.22695L107.557 8.5315C106.19 7.38225 104.42 6.72998 102.432 6.72998C97.3068 6.72998 93.6416 10.4262 93.6416 15.6133C93.6416 20.8315 97.3068 24.5588 102.432 24.5588C104.389 24.5588 106.128 23.9376 107.495 22.8194L107.992 23.9997H112.464V7.22695H108.147ZM103.208 19.4648C101.034 19.4648 99.4499 17.8186 99.4499 15.6133C99.4499 13.4391 101.034 11.8239 103.208 11.8239C105.383 11.8239 106.967 13.4391 106.967 15.6133C106.967 17.8186 105.383 19.4648 103.208 19.4648Z" fill="#2A1F71" />
		</svg>
	);
}
