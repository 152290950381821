import React, { useState } from 'react';
import ProfileForm from '../components/profile/ProfileForm';

export default function Profile() {
	const [message, setMessage] = useState('');
	const next = () => {
		setMessage('Your profile was updated correctly');
		setTimeout(() => {
			setMessage('');
		}, 3000);
	};
	return (
		<div className="grey100 pver-2">
			<div className="max-w-700px mhor-auto">
				<h1 className="h2 t-blue mver-2 mobile:h5">
					Edit Profile
				</h1>
				<div className="white mb pt pb-2 phor-2 mobile:phor mt mobile:mhor-0 br">
					<ProfileForm next={next} action="Save Profile" />
				</div>
				{message && <div className="t-blue center">{message}</div>}
			</div>
		</div>
	);
}
