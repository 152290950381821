import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import Input from '../ui/forms/Input';
import Genders from './Genders';
import Age from './Age';
import Parties from './Parties';
import Error from '../ui/forms/Error';
import Button from '../ui/forms/Button';
import { emailAtom, loadingPetitionBar, userAtom } from '../../data/atoms';
import { addEmail, getSoftUser, saveSoftUser, updateSoftUser } from '../../data/api';
import { identifyUser, sendSimpleEvent } from '../ui/Events';

interface FormProps{
	next: () => void;
	action: string;
}

export default function ProfileForm({ next, action }:FormProps) {
	const localemail = window.localStorage.getItem('email');
	let navigate = useNavigate();
	const [error, setError] = useState('');
	const [emailLocal, setEmailLocal] = useRecoilState(emailAtom);
	const [email, setEmail] = useState(emailLocal);
	const [userLocal, setUserLocal] = useRecoilState(userAtom);
	const [loading, setLoading] = useRecoilState(loadingPetitionBar);

	const [user, setUser] = useState({
		firstName: userLocal?.attributes?.firstName || '',
		lastName: userLocal?.attributes?.lastName || '',
		gender: userLocal?.attributes?.gender || '',
		age: userLocal?.attributes?.age || '',
		party: userLocal?.attributes?.party || ''
	});

	const getUser = async () => {
		if (localemail) {
			let u = await getSoftUser({ email: localemail?.toLowerCase() });
			if (u.data.length > 0) {
				setUser(u.data[0].attributes);
			}
		}
	};

	const validateEmail = (em:string) => {
		const eml = String(em).toLowerCase();
		return eml.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
	};
	const saveProfile = async () => {
		setError('');
		if (!user.firstName || !user.lastName) {
			setError('Please enter first name and last name to continue');
			return;
		}
		if (!email) {
			setError('Please enter an email to continue');
			return;
		}
		if (!validateEmail(email)) {
			setError('Please enter a valid email');
			return;
		}
		if (!user.gender) {
			setError('Please enter a gender to continue');
			return;
		}
		if (!user.age) {
			setError('Please enter age range to continue');
			return;
		}
		if (!user.party) {
			setError('Please enter a political party to continue');
			return;
		}
		console.log('Passed Profile form errors');
		if (email === emailLocal && window.localStorage.getItem('email')) {
			console.log('Equals');
			const us = await updateSoftUser(userLocal?.id, user);
			window.localStorage.setItem('email', email);
			setUserLocal(us.data);
			if (emailLocal === email) {
				console.log('next occurs in effect');
			} else {
				setEmailLocal(email);
			}

			identifyUser({
				id: userLocal?.id,
				attributes: { ...user }
			});
		} else {
			console.log('Not equals');
			let u = await getSoftUser({ email });
			if (u.data.length > 0) {
				console.log('Exists', u.data);
				await updateSoftUser(u.data[0].id, user);
				window.localStorage.setItem('email', email);
				setUserLocal(u.data[0]);
				if (emailLocal === email) {
					console.log('next occurs in effect');
				} else {
					setEmailLocal(email);
				}
				identifyUser({
					id: userLocal?.id,
					attributes: { ...user }
				});
			} else {
				console.log('New');
				const us = await saveSoftUser({ ...user, email });
				console.log('New', us);
				window.localStorage.setItem('email', email);
				setUserLocal(us.data);
				if (emailLocal === email) {
					console.log('next occurs in effect');
				} else {
					setEmailLocal(email);
				}
				sendSimpleEvent({
					event: 'action',
					title: 'User Joined'
				});
				identifyUser(us.data);
				await addEmail({ email, name: `${user.firstName} ${user.lastName}` });
			}
		}
	};

	const save = async () => {
		console.log('Saving');
		await saveProfile();
		console.log('Passing to next step');
	};
	const changeInput = (e:any) => {
		setUser({ ...user, [e.target.name]: e.target.value });
	};
	useEffect(() => {
		// setEmail(emailLocal);
		console.log('Email set', emailLocal);
		if (emailLocal) {
			next();
		}
	}, [emailLocal]);
	useEffect(() => {
		// setEmail(emailLocal);
		console.log('Updated User Local', emailLocal);
		if (emailLocal && emailLocal === email) {
			console.log('Updated User Local & email was already set');
			next();
		}
	}, [userLocal]);
	useEffect(() => {
		if (loading) {
			getUser();
			setLoading(false);
		}
	}, [loading]);

	return (
		<div className="left">
			<div className="grid2 col-gap mb mobile:mb-half">
				<Input name="firstName" id="firstName" value={user?.firstName} onChange={changeInput} placeholder="First Name" label="First Name" />
				<Input name="lastName" id="lastName" value={user?.lastName} onChange={changeInput} placeholder="Last Name" label="Last Name" />
			</div>
			<div className="mb grid mobile:mb-half">
				<Input name="email" id="emailP" placeholder="Email" label="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
			</div>
			<div className="grid2 col-gap mb mobile:mb-half">
				<Genders value={user?.gender} onChange={changeInput} />
				<Age value={user?.age} onChange={changeInput} />
			</div>
			<div className="mb grid mobile:mb-half">
				<Parties value={user?.party} onChange={changeInput} />
			</div>
			{error && <Error error={error} />}
			<div className="mt-2 mobile:mt center">
				<Button type="button" color="red" onClick={save}>
					{action}
				</Button>
			</div>
		</div>
	);
}
