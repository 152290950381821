import React, { useEffect, useState } from 'react';
import { getAge } from '../../data/api';

interface GenderAttr {
	label: string;
}

interface GenderItems {
	attributes: GenderAttr;
	id: number;
}

interface GenderProps {
	onChange?: (e:any) => void;
	value?: string;
}

export default function Age({ onChange, value }:GenderProps) {
	const [loading, setLoading] = useState(true);
	const [items, setItems] = useState<GenderItems[]>([]);
	const entry = async () => {
		const data = await getAge();
		setItems(data.data);
		setLoading(false);
	};
	useEffect(() => {
		if (loading) {
			entry();
		}
	}, [loading]);
	if (loading) {
		return (
			<div className="center p-2">
				Loading...
			</div>
		);
	}

	return (
		<div className="relative mt">
			<label htmlFor="genders">
				<span className="label pver-half relative f-600 f-1rem mobile:f-small white inline-flex">Age Range</span>
				<select
					name="age"
					className="w-100 h-4rem phor-2 f-medium mobile:f-1rem mobile:phor mobile:h-3rem b b-grey200 br-half"
					id="age"
					onChange={onChange}
					value={value}
				>
					<option>Select One...</option>
					{items.map(({ id, attributes }) => (
						<option key={`age${id}`} value={attributes.label}>
							{attributes.label}
						</option>
					))}
				</select>
			</label>
		</div>
	);
}
