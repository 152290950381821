import React, { useState } from 'react';
import NavItem from './NavItem';

export default function NavMenu() {
	const [menu] = useState(
		[
			{
				title: 'Polls',
				url: 'polls'
			},
			{
				title: 'Petitions',
				url: 'petitions'
			},
			{
				title: 'Articles',
				url: 'read'
			}
		]
	);

	return (
		<nav className="flex">
			{menu.map(({ title, url }) => (
				<NavItem key={url} title={title} url={url} />
			))}
		</nav>
	);
}
