import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { Link } from 'react-router-dom';
import Button from '../ui/forms/Button';
import { loadingPetitionBar } from '../../data/atoms';
import { sendEvent } from '../ui/Events';
import { slugify } from '../../data/api';

interface PetitionsListBoxProps {
	cover?: string;
	title?: string;
	date?: string;
	id?: string;
	className?: string;
}

export default function PetitionsListBox({
	title, id, date, cover, className }:PetitionsListBoxProps) {
	const [loadingBar, setLoadingBar] = useRecoilState(loadingPetitionBar);
	const createEvent = () => {
		setLoadingBar(true);
		sendEvent({
			event: 'click',
			title,
			id,
			type: 'petition',
			position: 'feed'
		});
	};
	return (
		<Link to={`/petitions/${id}/${slugify(title)}`} className={className} onClick={createEvent}>
			<div className="grey100 grid2 col-gap-2 p-2 br mobile:block mobile:p">
				<img src={cover || 'https://res.cloudinary.com/thepark/image/upload/v1659845544/placeholder_8152618af4.jpg?updated_at=2022-08-07T04:12:24.952Z'} alt="Petitions" className="br w-100 ratio-16-9 obj-cover mobile:mb" />
				<div className="flex align-center">
					<div className="mobile:pb">
						<h3 className="h4 t-blue mobile:h5 mb">
							{ title || 'Petition' }
						</h3>
						<Button type="button" color="red">
							Sign Now
						</Button>
					</div>
				</div>
			</div>
		</Link>
	);
}
