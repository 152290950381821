import React from 'react';
import {
	EmailShareButton,
	FacebookShareButton,
	LinkedinShareButton,
	PinterestShareButton,
	RedditShareButton,
	TelegramShareButton,
	TumblrShareButton,
	TwitterShareButton,
	WhatsappShareButton
} from 'react-share';
import FbIcon from '../ui/icons/FbIcon';
import PinIcon from '../ui/icons/PinIcon';
import TTIcon from '../ui/icons/TTIcon';
import WUIcon from '../ui/icons/WUIcon';
import LIIcon from '../ui/icons/LIIcon';
import RDIcon from '../ui/icons/RDIcon';
import TLIcon from '../ui/icons/TLIcon';
import TBIcon from '../ui/icons/TBIcon';

function Box({ children, color }) {
	return (
		<div
			style={{ backgroundColor: color || 'black' }}
			className="mhor-quarter h-2halfrem w-2halfrem flex align-center br-quarter justify-center mb-half"
		>
			{children}
		</div>
	);
}

export default function Share({ url, title, image, description }) {
	return (
		<div className="mver mobile:grid4 mobile:max-w-200px mobile:mhor-auto">
			<FacebookShareButton title={title} url={url}>
				<Box color="#4267B2">
					<FbIcon />
				</Box>
			</FacebookShareButton>
			<TwitterShareButton title={title} url={url}>
				<Box color="#55acee">
					<TTIcon />
				</Box>
			</TwitterShareButton>
			<LinkedinShareButton url={url} title={title}>
				<Box color="#0077b5">
					<LIIcon />
				</Box>
			</LinkedinShareButton>
			<PinterestShareButton
				description={description}
				title={title}
				url={url}
				media={image}
			>
				<Box color="#CB2027">
					<PinIcon />
				</Box>
			</PinterestShareButton>
			<WhatsappShareButton url={url} title={title} separator=" | ">
				<Box color="#25d366">
					<WUIcon />
				</Box>
			</WhatsappShareButton>
			<RedditShareButton url={url} title={title}>
				<Box color="#ff4500">
					<RDIcon />
				</Box>
			</RedditShareButton>
			<TelegramShareButton url={url} title={title}>
				<Box color="#0088cc">
					<TLIcon />
				</Box>
			</TelegramShareButton>
			<TumblrShareButton url={url} title={title} caption={description}>
				<Box color="#32506d">
					<TBIcon />
				</Box>
			</TumblrShareButton>
		</div>
	);
}
