import React from 'react';
import { Link } from 'react-router-dom';

interface ButtonProps{
	type?: string;
	link?:string;
	color?:string;
	height?:string;
	className?:string;
	fontSize?:string;
	children: React.ReactNode;
	onClick?: () => void;
}

export default function Button({
	type, children, link, color, height, fontSize, onClick, className
}:ButtonProps) {
	const cn = `${height ? height : 'h-3rem'} ${color ? color : 'blue'} t-white inline-flex align-center f-900 phor-2 mobile:phor br-2 justify-center ${className ? className : ''} ${fontSize ? fontSize : 'f-1rem'} f-uppercase b-0 cursor-pointer`;
	if (type === 'link') {
		return (<Link className={cn} to={link ? link : '/'} onClick={onClick}>{children}</Link>);
	}
	if (type === 'anchor') {
		return (<a className={cn} href={link ? link : '/'} onClick={onClick}>{children}</a>);
	}
	if (type === 'button') {
		return (<button className={cn} type="button" onClick={onClick}>{children}</button>);
	}
	return (<a className={cn} href={link ? link : '/'}>{children}</a>);
}
